import React, { Component } from 'react';
import { BrowserLanguage } from '../../entities/BrowserLanguage';
import CloseIcon from '@material-ui/icons/Close';
import './Layers.scss'
import Switch from "react-switch";
import station_2_icon from '../../assets/images/station_2_icon.png';
import busy_station_icon from '../../assets/images/busy_station_icon.png';
import inactive_station_icon from '../../assets/images/inactive_station_icon.png';
import comingSoon_station_icon from '../../assets/images/comingSoon_station_icon.png';
import layers_icon from '../../assets/images/layers_icon_in_popup.png';
import { StringsData } from '../../entities/StringsData';

const heightSwitch = 26;
const widthSwitch = 60;

class Layers extends Component<LayersProps>{


    render() {
        const { openLayersPopup, layersPopup } = this.props;
        return (
            <div className={this.getPopupClassName()}>
                {openLayersPopup &&
                    <div   >
                        <div className="close-icon-layers"
                            onClick={() => layersPopup()}
                        >
                            <CloseIcon />
                        </div>
                        <div className="layers">
                            <div className="layers-img-title">
                                <img src={layers_icon} alt="layers icon" onClick={() => layersPopup()} />
                                <span className="layers-title" onClick={() => layersPopup()}>{this.props.stringsFromServer.site_LayesTitle}</span>
                            </div>

                            <div className="filters-layers">
                                {this.getAvailablJSX()}
                                {this.getInactiveJSX()}
                                {this.getBusylJSX()}
                                {/* {this.getComingSoonJSX()} */}
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }

    getPopupClassName(): string {
        const { browserLanguage, openLayersPopup } = this.props;

        if (!openLayersPopup && (browserLanguage === BrowserLanguage.he || browserLanguage === BrowserLanguage.ar)) {
            return ('not-show-layers-popup-he');
        }
        if (!openLayersPopup) {
            return ('not-show-layers-popup-en');
        }
        if (browserLanguage === BrowserLanguage.he || browserLanguage === BrowserLanguage.ar) {
            return ('layers-popup-he');
        }
        return ('layers-popup-en');
    }

    getAvailablJSX(): JSX.Element {
        const { availableHandle, availableFilter, stringsFromServer } = this.props;
        return (
            <div className="available">
                <div className="location-icon">
                    <img src={station_2_icon} alt="location icon" ></img>
                </div>
                <div className="text-img-switch">
                    <span className="available-switch-title switch-toggle-title">{stringsFromServer.site_LayersAvailable}</span>
                    <span className="switch-component">
                        <Switch
                            onChange={() => availableHandle()}
                            checked={availableFilter}
                            className="react-switch"
                            height={heightSwitch}
                            width={widthSwitch}
                        />
                    </span>
                    <div className="available-switch-text switch-toggle-text">
                        {stringsFromServer.site_LayersAvailableText}
                    </div>
                </div>
            </div>
        )
    }

    getInactiveJSX(): JSX.Element {
        const { inactiveHandle, inactiveFilter, stringsFromServer } = this.props;
        return (
            <div className="inactive">
                <div className="location-icon">
                    <img src={inactive_station_icon} alt="location icon" ></img>
                </div>
                <div className="text-img-switch">
                    <span className="inactive-switch-title switch-toggle-title">{stringsFromServer.site_LayersInactive}</span>
                    <span className="switch-component">
                        <Switch
                            onChange={() => inactiveHandle()}
                            checked={inactiveFilter}
                            className="react-switch"
                            height={heightSwitch}
                            width={widthSwitch}
                        />
                    </span>
                    <div className="inactive-switch-text switch-toggle-text">
                        {stringsFromServer.site_LayersNotActiveTxt}
                    </div>
                </div>
            </div>
        )
    }

    getBusylJSX(): JSX.Element {
        const { busyHandle, busyFilter, stringsFromServer } = this.props;
        return (
            <div className="busy">
                <div className="location-icon">
                    <img src={busy_station_icon} alt="location icon" ></img>
                </div>
                <div className="text-img-switch">
                    <span className="busy-switch-title switch-toggle-title">{stringsFromServer.site_LayersBusy}</span>
                    <span className="switch-component">
                        <Switch
                            onChange={() => busyHandle()}
                            checked={busyFilter}
                            className="react-switch"
                            height={heightSwitch}
                            width={widthSwitch}
                        />
                    </span>
                    <div className="busy-switch-text switch-toggle-text">
                        {stringsFromServer.site_LayersBusyTxt}
                    </div>
                </div>
            </div>
        )
    }

    getComingSoonJSX(): JSX.Element {
        const { comingSoonHandle, comingSoonFilter, stringsFromServer } = this.props;
        return (
            <div className="coming-soon">
                <div className="location-icon">
                    <img src={comingSoon_station_icon} alt="location icon" ></img>
                </div>
                <div className="text-img-switch">
                    <span className="coming-soon-switch-title switch-toggle-title">{stringsFromServer.site_LayersComingSoon}</span>
                    <span className="switch-component">
                        <Switch
                            onChange={() => comingSoonHandle()}
                            checked={comingSoonFilter}
                            className="react-switch"
                            height={heightSwitch}
                            width={widthSwitch}
                        />
                    </span>
                    <div className="coming-soon-switch-text switch-toggle-text">
                        {stringsFromServer.site_LayersComingSoonText}
                    </div>
                </div>
            </div>
        )
    }

}
export default Layers;

interface LayersProps {
    layersPopup: Function;
    browserLanguage: BrowserLanguage;
    openLayersPopup: boolean;
    availableHandle: Function;
    availableFilter: boolean;
    inactiveFilter: boolean;
    busyFilter: boolean;
    comingSoonFilter: boolean;
    inactiveHandle: Function;
    busyHandle: Function;
    comingSoonHandle: Function;
    stringsFromServer: StringsData;

}