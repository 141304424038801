import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { PopupType } from '../../entities/PopupType';
import './Popup.scss';
import CloseIcon from '@material-ui/icons/Close';
import { BrowserLanguage } from '../../entities/BrowserLanguage';
import write_to_us_icon from '../../assets/images/logo-V-03.png'
import { StringsData } from '../../entities/StringsData';
import { Alert } from '@material-ui/lab';
import axios from 'axios';
import * as config from "../../appConfig.json";
import { MenuItem, Select } from '@material-ui/core';

class Popup extends Component<PopupProps, PopupState> {
    constructor(props: PopupProps) {
        super(props);
        this.state = {
            openPopup: this.props.openPopup,
            requestSubject: '',
            name: '',
            email: '',
            carNumber: '',
            phoneNumber: '',
            remarks: '',
            nameError: false,
            emailError: false,
            carNumberError: false,
            phoneNumberError: false,
            requestSubjectError: false,
        }
    }
    render() {
        return (
            <div className="popup">
                <Dialog
                    maxWidth='md'
                   // scroll='paper'
                    open={this.state.openPopup}
                    onClose={this.closePopup}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    
                >
                    <div className="close-icon-popup" onClick={() => this.closePopup()} >
                        <CloseIcon />
                    </div>
                    {this.getPopupContent()}
                </Dialog>
            </div>
        )
    }

    getPopupContent = () => {
        switch (this.props.popupType) {

            case (PopupType.POPUP_CONTACT):
                return (this.popupContactJSX());
            case (PopupType.TERMS_OF_USE):
                return (this.popupTermsJSX());
            case (PopupType.ACCESSIBILITY_STATMENT):
                return (this.popupAccessibilityStatementJSX());
        }
    }



    popupContactJSX = (): JSX.Element => {
        const { stringsFromServer, browserLanguage } = this.props;
        return (
            <div className={`popup-contact ${browserLanguage}`}>
                <div className="popup-title">
                    <img
                        className="write-to-us-icon"
                        src={write_to_us_icon}
                        alt="write to us icon"
                    ></img>
                     <br/>
                     {stringsFromServer.site_ContactUsTitle} 
                </div>
                
                <div className="form">
                    <div className="rtl">
                        <TextField className="subject-contact"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.setState({ requestSubject: event.target.value, requestSubjectError: false })}
                            label={stringsFromServer.site_ContactUsSubject}
                            error={this.state.requestSubjectError}
                            required select>
                                <MenuItem value={"2"}>דיווח על שגיאה</MenuItem>
                                <MenuItem value={"1"}>בקשת מידע</MenuItem> 
                        </TextField>               
                    </div>

                    <div className="rtl">
                        <TextField
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.setState({ phoneNumber: event.target.value, phoneNumberError: false })}
                            label={stringsFromServer.site_ContactUsPhone}
                            error={this.state.phoneNumberError}
                            required />
                    </div>

                    <div className="rtl">
                        <TextField
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.setState({ name: event.target.value, nameError: false })}
                            label={stringsFromServer.site_ContactUsName}
                            error={this.state.nameError}
                            required />
                    </div>
                    <div className="rtl">
                        <TextField
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.setState({ carNumber: event.target.value, carNumberError: false })}
                            label={stringsFromServer.site_ContactUsCarNum}
                            error={this.state.carNumberError}
                            required />
                    </div>
                    <div className="rtl">
                        <TextField
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.setState({ email: event.target.value, emailError: false })}
                            label={stringsFromServer.site_ContactUsEmail}
                            error={this.state.emailError}/>
                    </div>
                    <div className="rtl contact-desc-div">
                        <TextField 
                            className="contact-desc"
                            id="outlined-multiline-static"
                            label={stringsFromServer.site_ContactUsContext}
                            multiline
                            rows={4}
                            onChange={(event: any) => this.setState({ remarks: event.target.value })}
                            defaultValue=""
                            InputProps={{ disableUnderline: true }}/>
                    </div>


       {/*              <div className='rtl'>
                        <FormControl className='application-description'
                            required >{stringsFromServer.site_ContactUsAppDesc}</FormControl>
                        <br />
                        <textarea
                            onChange={(event: any) => this.setState({ remarks: event.target.value })}
                            rows={8}
                            placeholder={stringsFromServer.site_ContactUsContext}
                        />
                    </div> */}
                </div>
              

                {this.getAlertMessege()}

                <div className="button">
                    <button
                        onClick={this.sendFormContact}
                    >
                        {stringsFromServer.site_ContactUsSend}
                    </button>
                </div>

            </div>
        )
    }
    //might be better to move these method out, as they are simple views; 
    // then just import them 
    popupTermsJSX = (): JSX.Element => {
        return (
            
            <div className="terms-popup">
                {/* </p> */}
                <h1>תנאי שימוש עמדות טעינה</h1>
                <h3>תקנון השימוש באתר הנ"ל נכתב בלשון זכר אך האמור בו מתייחס לנשים וגברים כאחד.</h3>
                <p></p>
                <ol>
                    <li>
                        <h4>כללי</h4>
                        <p>מדינת ישראל, באמצעות משרד האנרגיה (להלן: "המשרד") מציעה לציבור שימוש באתר מקוון זה - http://cellocharge.com/  (להלן: "האתר") ובמאגר המידע שלו ו/או אפליקציה צמודה לו האתר ו/או האפליקציה מציגים, בין היתר, מפה של עמדות לטעינת כלי רכב חשמליים של כלל המפעילים בפריסה ארצית ומידע אודות עמדות אלו (כלל המידע המוצג באתר יכונה להלן: "המידע"). המשרד רואה באתר ו/או האפליקציה אלו צעד חשוב בהנגשה לציבור של פריסת עמדות הטעינה הציבוריות לצורך קידום תחבורה חשמלית.</p>
                        <p>מובהר כי השימוש באתר ו/או האפליקציה הינם בכפוף לכל דין ולתנאי השימוש המפורטים להלן. השימוש באתר ו/או האפליקציה, במידע, בתכנים ובשירותים הניתנים במסגרתם מהווה אישור כי המשתמש/ת קרא/ה בעיון את תנאי השימוש, הבינ/ה אותם, מסכימ/ה להם ומתחייב/ת למלא אחריהם. משתמש/ת שאינו/ה מסכים/ה לאיזה מבין התנאים מתנאי השימוש מתבקש/ת להימנע מכל שימוש או ניסיון שימוש באתר ו/או האפליקציה. לפיכך, המשתמש/ת מתבקש/ת לקרוא את תנאי השימוש באתר זה בעיון ובקפידה, בטרם השימוש באתר ו/או האפליקציה.                            
                        </p>
                        <p>כמו כן, מובהר כי תנאי השימוש חלים על כל צפייה או שימוש באתר ו/או האפליקציה, בכל אמצעי או מכשיר שהוא לרבות באמצעות מחשב או מכשיר תקשורת אחר (כגון טלפון סלולארי, מחשבי כף יד למיניהם וכיו"ב), בין באמצעות רשת האינטרנט ובין באמצעות כל רשת או אמצעי תקשורת אחרים (להלן: "מכשיר").</p>
                        <p>לעניין תנאי השימוש המונח "מדינת ישראל" משמעו בנוסף למשרד, עובדיה, נציגיה וספקי שירותים עבורה או מי מטעמם.</p>
                        <p>המשרד רשאי לעדכן מעת לעת את תנאי השימוש באתר ו/או האפליקציה, או כל מסמך אחר שיבוא במקומו או בנוסף לו בהתאם לשיקול דעתו הבלעדי וללא צורך במסירת התראה מראש, ולפיכך מומלץ לבדוק את תנאי השימוש באתר ו/או האפליקציה מעת לעת. שינוי או עדכון של תנאי השימוש יכנסו לתוקף באופן מידי אלא אם קבע המשרד אחרת.</p>
                        {/* <p>אתר הצגת עמדות טעינה חשמלייים (להלן "האתר") הוא אתר המשתמש כאתר להצגת עמדות טעינה חשמלייים ייצוגי עבור הצגת עמדות טעינה חשמלייים והנך מוזמן לקחת בו חלק בכפוף להסכמתך לתנאי השימוש אשר יפורטו להלן</p>
                        <p>בנוסף השימוש באתר זה על כל תכניו והשירותים המוצעים בו, הורדות של קבצים, מדיה כגון תמונות וסרטונים והתכנים השונים המוצעים לקהל המבקרים עשויים להשתנות מעת לעת או בהתאם לסוג התוכן.</p>
                        <p>הנהלת האתר שומרת לעצמה הזכות לעדכן את תנאי השימוש המוצגים להלן מעת לעת וללא התראה או אזכור מיוחד בערוצי האתר השונים.</p> */}
                    </li>
                    <li>
                        <h4>תוכן האתר ו/או האפליקציה והשימוש בהם</h4>
                        <p>
                             
                        </p>
                        {/* <p>האתר כמו גם כל המידע שבו לרבות עיצוב האתר, קוד האתר, קבצי מדיה לרבות גרפיקות, סרטונים, תמונות, טקסטים, קבצים המוצעים להורדה וכל חומר אחר אשר מוצג באתר שייכים במלואם לאתר הנ"ל ומהווים קניין רוחני בלעדי של אתר הצגת עמדות טעינה חשמלייים ואין לעשות בהם שימוש ללא אישור כתוב מראש מאתר הצגת עמדות טעינה חשמלייים.</p>
                        <p>בנוסף אין להפיץ, להעתיק, לשכפל, לפרסם, לחקות או לעבד פיסות קוד, גרפיקות, סרטונים, סימנים מסחריים או כל מדיה ותוכן אחר מבלי שיש ברשותכם אישור כתוב מראש.</p> */}
                    </li>
                    <li>
                        <h4>תוכן האתר</h4>
                        <p>אנו שואפים לספק לכם את המידע המוצג באתר ללא הפרעות אך יתכנו בשל שיקולים טכניים, תקלות צד ג או אחרים, הפרעות בזמינות האתר. ולכן איננו יכולים להתחייב כי האתר יהיה זמין לכם בכל עת ולא יינתן כל פיצוי כספי או אחר בשל הפסקת השירות / הורדת האתר.</p>
                        <p>קישורים לאתר חיצוניים אינם מהווים ערובה כי מדובר באתרים בטוחים, איכותיים או אמינים וביקור בהם נעשה על דעתכם האישית בלבד ונמצאים בתחום האחריות הבלעדי של המשתמש באתר.</p>
                        <p>התכנים המוצעים באתר הינם בבעלותם הבלעדית של הצגת עמדות טעינה חשמלייים ואין לעשות בהם שימוש אשר נועד את האמור בתקנון זה (ראה סעיף 3) למעט במקרים בהם צוין אחרת או במקרים בהם צוין כי זכויות היוצרים שייכים לגוף חיצוני. במקרים אלו יש לבדוק מהם תנאי השימוש בקישור המצורף ולפעול על פי המצוין באתר החיצוני לו שייכים התכנים.</p>
                    </li>
                    <li>
                        <h4>ניהול משתמשים ומבקרים באתר</h4>
                        <p>הנהלת האתר שומרת לעצמה את הזכות לחסום כל משתמש ובין אם על ידי חסימת כתובת הIP  של המחשב שלו, כתובת הMACID  של המחשב שלו או אפילו בהתאם למדינת המוצא ללא צורך לספק תירוץ אשר מקובל על הגולש.</p>
                        <p>צוות האתר / הנהלת האתר יעשה כל שביכולתו להגן על פרטי המשתמשים הרשומים באתר / מנויים הרשומים באתר. במקרים בהם יעלה בידיו של צד שלישי להשיג גישה למידע מוסכם בזאת כי לגולשים, משתמשים וחברים באתר לה תהה כל תביעה, טענה או דרישה כלפי צוות האתר הצגת עמדות טעינה חשמלייים.</p>
                    </li>
                    <li>
                        <h4>גילוי נאות</h4>
                        <p>באתר זה עשוי לעשות שימוש בקבצי קוקיז (במיוחד עבור משתמשים רשומים ומנויים) ובממשקי סטטיסטיקה פנימיים בכדי לשמור תיעוד סטטיסטי אנונימי של גולשים וניתוח תנועת הגולש/ים, הרגלי גלישה באתר וניתוח קליקים וזמן שהייה.</p>
                        <p>בכל העת ולבד מאשר גולשים המחוברים לאתר המידע הנשמר הוא אנונימי לחלוטין ואין בו את שם הגולש או כל פרט מזהה אחר.</p>
                    </li>
                    <li>
                        <h4>איזור שיפוט</h4>
                        <p>בעת שאתם עושים שימוש באתר ובמקרה בו התגלעה כל מחולקת אתם מסכימים להלן כי האמור לעיל נמצא תחת סמכות השיפוט הבלעדי של החוק הישראלי תוך שימוש במערכת בתי המשפט הישראליים בלבד במחוז תל אביב.</p>
                    </li>
                </ol>
                {/* <p>	 */}

            </div>
        )
    }

    popupAccessibilityStatementJSX = (): JSX.Element => {
        return (
            <div className="accessibility-popup">
                <div className="accessibility-title">
                    הצהרת נגישות
                </div>
                <div className="accessibility-text">
                    <span className="the-company">
                        סלופארק טכנולוגיות בע"מ
                    </span>
                    &nbsp;
                    <span>
                        רואה חשיבות רבה במתן הזדמנות שווה לכל גולשי האתר.
                        לשם כך מושקעים מאמצים ומשאבים רבים בהנגשת האתר לשביעות רצון הגולשים.
                        ההנגשה נועדה להתאים את האתר כך שיהיה זמין, נוח וידידותי לשימוש עבור אנשים עם מוגבלויות.
                    </span>
                </div>
                <div className="accessibility-text">
                    <span>
                        האתר פועל בהתאם להנחיות הנגישות הקבועות בתקן ישראלי רשמי מספר 5568 – שעניינו "קווים מנחים לנגישות תכנים באינטרנט" לרמה AA.
                        תקן ישראלי זה זהה למסמך הקווים המנחים של הארגון הבינלאומי העוסק בתקינה ברשת
                        – Web Content Accessibility Guidelines (WCAG) 2.0.
                    </span>
                </div>
                <div className="accessibility-text">
                    <span className="buld">
                        בעברית:
                    </span>
                    &nbsp;
                    <span>
                        <a href='https://www.w3c.org.il/guidelines_wcag_2-0' target="_blank"> WCAG 2.0 הנחיות לנגישות תכנים באינטרנט</a>
                    </span>
                    <br />
                    <span className="buld">
                        באנגלית:
                    </span>
                    &nbsp;
                    <span>
                        <a href='https://www.w3.org/TR/WCAG20/' target="_blank">Web Content Accessibility Guidelines (WCAG) 2.0</a>
                    </span>
                </div>
                <div className="accessibility-text">
                    <div className="sub-title">
                        אמצעי הנגישות באתר
                    </div>
                    <span>
                        תקינה – האתר תומך בכל הדפדפנים הפופולריים כגון: Internet Explorer, Google Chrome, Firefox ועוד..
                    </span>
                </div>
                <div className="accessibility-text">
                    <div className="sub-title">
                        שירות לקוחות נגיש
                    </div>
                    <div>
                        אנו בחברת סלופארק מאפשרים לכלל לקוחותינו שירות שוויוני, נוח, איכותי ובטיחותי. ללקוחותינו המתקשים בתקשורת טלפונית רגילה, לשם כך ביצענו:
                    </div>
                    <span>
                        הדרכות עובדים לשירות נגיש: הדרכנו והכשרנו את העובדים בהיבטי הנגישות השונים, מטרת ההדרכות ליצור מודעות בקרב עובדי הקבוצה בנושא נגישות ולהקנות להם כלים מעשיים להענקת שירות נגיש.
                    </span>
                </div>
                <div className="accessibility-text">
                    <div className="sub-title">
                        דרכי פנייה לבקשות והצעות
                    </div>
                    <div>
                        אנחנו משתדלים לשמור ולתחזק את האתר ברמה הטובה ביותר, אך מאחר ואנו מעלים עדכונים באופן שוטף,
                        ייתכן שגם בהמשך יהיו אי אלו דפים או תכנים מסוימים שיימצאו לא מתאימים במלואם לדרישות התקן הנ"ל,
                        אולם כל זאת שלא בכוונת מתכנן אלא עקב טעויות העלולות לקרות בתום לב
                    </div>
                    <div>
                        לשם כך, נרתם צוות האתר לבצע באופן תדיר בדיקות לעמידה בדרישות התקן,
                        אם בכל זאת מצאת ו/או נתקלת בקשיים בגלישה בנושא הנגישות, אל תהססו להפנות את תשומת ליבנו לכך.
                    </div>
                    <span>
                        כדי שנוכל לטפל בבעיה בדרך הטובה ביותר בבקשה צרפו פרטים מלאים ככל שניתן
                        (תיאור הבעיה, מהי הפעולה שניסיתם לבצע, באיזה דף גלשתם וסוג הטכנולוגיה המסייעת במידה והשתמשתם.)
                        אם ברצונכם לקבל משוב אישי אנא ציינו בפנייתכם את שמכם המלא ואת אופן ההתקשרות הרצוי
                        (טלפון, דוא"ל או כתובת למשלוח דואר)
                    </span>
                </div>

                <div className="accessibility-text">
                    <div>
                        רכז נגישות: איציק חתשואל
                    </div>
                    <div>
                        טלפון: 050-6007462
                    </div>
                    <div>
                        דואר אלקטרוני:<a href="mailto:itzik@cellopark.com" target="_blank">itzik@cellopark.com</a>
                    </div>
                </div>

                <div className="accessibility-text">
                    חברת סלופארק שואפת ללמוד ולשפר את הנגישות ללקוחותיה ואנו לומדים כל העת ומפיקים לקחים מלקוחותינו,
                    על כן אנו מודים מראש לכל הפונים אלינו.
                </div>

            </div>
        )
    }

    checkForm(): boolean {
        const phoneNumberCheck = /^0(5[^7]|[2-4]|[8-9]|7[0-9])[0-9]{7}$/;
        const emailCheck = /\S+@\S+\.\S+/;

        let validForm: boolean = true;
        if (this.state.requestSubject === '') {
            this.setState({ requestSubjectError: true })
            validForm = false;
        }
        if (!phoneNumberCheck.test(this.state.phoneNumber)) {
            this.setState({ phoneNumberError: true })
            validForm = false;
        }
        if (this.state.name === '') {
            this.setState({ nameError: true })
            validForm = false;
        }
        if (!emailCheck.test(this.state.email) && this.state.email != "") {
            this.setState({ emailError: true })
            validForm = false;
        }
        if (this.state.carNumber === '') {
            this.setState({ carNumberError: true })
            validForm = false;
        }
        return (validForm);
    }




    sendFormContact = () => {
        if (!this.checkForm()) return;
        this.setState({ successMeseg: 'info' })
        const URL = config.SERVER_URL + 'ChargingSystemPortal/api/contactUS';
        const JSON_data = {
            Subject: this.state.requestSubject,
            PhoneNumber: this.state.phoneNumber,
            Name: this.state.name,
            Email: this.state.email,
            CarNumber: this.state.carNumber,
            Remarks: this.state.remarks
        }

        axios.post(URL, JSON_data)
            .then(response => {
                this.setState({ successMeseg: 'success' })
                console.info("response", response);

            })
            .catch(err => {
                console.error(err);
                this.setState({ successMeseg: 'error' })
                // this.setState({ showLoader: false })
            });
    }

    getAlertMessege(): JSX.Element {
        const { stringsFromServer } = this.props;
        switch (this.state.successMeseg) {
            case ('error'):
                return (<div className=""><Alert severity="error">{stringsFromServer.site_ContactUsBadRespMsg} </Alert></div>);
            case ('info'):
                return (<div className=""><Alert severity="info">{stringsFromServer.site_ContactUsSendigMsg}</Alert></div>);
            case ('success'):
                return (<div className=""><Alert severity="success">{stringsFromServer.site_ContactUsRespMsg}</Alert></div>);
            default:
                return (<div className=""></div>)

        }
    }



    closePopup = () => {
        this.setState({ openPopup: false })
        this.props.closePopup();
    }
}
export default Popup;

interface PopupProps {
    closePopup: Function;
    popupType: PopupType
    openPopup: boolean;
    browserLanguage: BrowserLanguage;
    stringsFromServer: StringsData;
}

interface PopupState {
    openPopup: boolean;
    requestSubject: string;
    phoneNumber: string;
    name: string;
    email: string;
    carNumber: string;
    remarks: string;
    successMeseg?: 'success' | 'info' | 'error';
    requestSubjectError: boolean;
    phoneNumberError: boolean;
    nameError: boolean;
    emailError: boolean;
    carNumberError: boolean;
}