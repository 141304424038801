import React, { Component, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
//import {searchParams} from 'react-router-dom';
//import queryString from 'query-string';
import exampleAPI_Json from '../entities/exampleAPI.json';
import exampleFilter_Json from '../entities/exampleFilter.json';
import IconMap from '../components/IconMap/IconMap';
import { AddressInfo, Coordinates, MoreInfoStationData, SearchOption, ConstClass, BoundsCord, Cluster } from '../entities/iconData';
import DefaultCenter from '../entities/DefaultCenter';
import axios from "axios";
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import './Map.scss';
import Layers from '../components/Layers/Layers'
import Filter from '../components/Filter/Filter'
import { BrowserLanguage } from '../entities/BrowserLanguage';
import { LayersFilters } from '../entities/LayersFilters';
import MoreInfo from '../components/MoreInfo/MoreInfo';
import { MoreInfoData } from '../entities/MoreInfoData';
import { StringsData, StringsDataDefault } from '../entities/StringsData';
import ClusterMap from '../components/ClusterMap/ClusterMap';
import { Divider } from '@material-ui/core';
import { type } from 'os';
import * as config from '../appConfig.json';
import Marker from '../components/Marker';

import cellopark_logo from '../assets/images/logo_with_shadow.png'
// import go_to_my_location from '../assets/images/pan_to_center_icon.png'
import go_to_my_location from '../assets/images/MyLocationIcon.png';


//import DeviceInfo from 'react-native-device-info';

const lang = 'lang';
const allTypesClass = new ConstClass();
const allTypesConnectors = allTypesClass.allTypesConnectors;
const allTypesOperators = allTypesClass.allTypesOperators;
const allTypesPayment = allTypesClass.allTypesPayment;
const allTypesIncludes = allTypesClass.allTypesIncludes;

const CLOSEUP_ZOOM_LEVEL = 14;
const INITIAL_ZOOM_LEVEL = 12;
const MIN_ZOOM = 7;
const toggleZoomIconClusterLarge = 10;
const toggleZoomIconClusterSmall = 9;
const EAST_POINT: number = 35.91458127949562;
const WEST_POINT: number = 34.20880676909863;
const NORTH_POINT: number = 33.3356239226505;
const SOUTH_POINT: number = 29.419712107266122;

const USER_ACTIVITY_ON_LOAD : number = 9;
const USER_ACTIVITY_ON_EXIT : number = 10;
const USER_ACTIVITY_NAVIGATE : number = 11;

const NAVIGATE_COORDS  = 'No navigation';

const markerStyle = {
    // position: 'absolute' as 'absolute',
    // top: "100%",
    // left: "50%",
    // transform: "translate(-50%, -100%)"
  };

class Map extends Component<MapProps, MapState>{
    defaultCenter = new DefaultCenter();    

    constructor(props: MapProps) {
            super(props);
        
         this.state = {
            defaultCenter: {
                lat: this.defaultCenter.latitude,
                lng: this.defaultCenter.longitude,
            },
            centerOfMap: {
                lat: this.defaultCenter.latitude,
                lng: this.defaultCenter.longitude,
            },
            mapZoom: INITIAL_ZOOM_LEVEL,
            point: '',
            lang:this.getLanguage(),
            stationData: [],
            filterStationData: [],
            //moreInfoData:[]] ,
            openFilterPopup: false,
            openLayersPopup: false,
            openMoreInfoPopup: false,
            closeAllPopups: false,
            socektNumber: 1,
            powerSelected: 5,
            selectedConnectorsArr: [allTypesConnectors],
            operatorsArr: [allTypesOperators],
            paymentsArr: [allTypesPayment],
            includeArr: [allTypesIncludes],
            availableFilter: true,
            inactiveFilter: true,
            busyFilter: true,
            comingSoonFilter: true,
            stringsFromServer: new StringsDataDefault(),
            boundsCord: {
                se: {
                    lat: 0,
                    lng: 0
                },
                sw: {
                    lat: 0,
                    lng: 0
                },
                nw: {
                    lat: 0,
                    lng: 0
                },
                ne: {
                    lat: 0,
                    lng: 0
                }
            },
            clusterArrLarge: [],
            clusterArrSmall: [],
            showIcons: true,
            userActivity:'',
            portalView:false,
            dashboardView:false
        }
   
        this.getLocation(); 
       
}

 

     getOS = () => {
        var userAgent = window.navigator.userAgent,
            platform = window.navigator.platform,
            macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
            windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
            iosPlatforms = ['iPhone', 'iPad', 'iPod'],
            os = null,
            os2 = navigator.userAgent.slice(13).split(';');
      
        if (macosPlatforms.indexOf(platform) !== -1) {
          os = 'Mac OS';
        } else if (iosPlatforms.indexOf(platform) !== -1) {
          os = 'iOS';
        } else if (windowsPlatforms.indexOf(platform) !== -1) {
          os =  'Windows';
        } else if (/Android/.test(userAgent)) {
          os = 'Android';
        } else if (!os && /Linux/.test(platform)) {
          os = 'Linux';
        }
      
        return os;
      }


    registerNavigation = (currActivity:number) => {
        const STRINGS_URL = config.SERVER_URL + "ChargingSystemPortal/api/UserActivity/";
        const deviceInfo = this.getOS();
        const navigate = (this.state.selectedStation!==undefined)?
             { lat: this.state.selectedStation.lat, lng: this.state.selectedStation.lng }
             : { lat: this.defaultCenter.latitude, lng: this.defaultCenter.longitude };

        //URL below should be in a global constant, 
        // even better would be to have it come from 
        // some configuration somewhere
        axios.get(STRINGS_URL + this.getOS()+ "/" + navigate.lat + "," 
                        + navigate.lng + "/" + currActivity)
            .then(response => {
                console.info("USER_ACTIVITY", deviceInfo);
                this.setState({ userActivity: 'NAVIGATE' });
            })
            .catch(err => {
                //Do we need to let the user know there was a problem? Maybe not?
                console.error(err);
            })
            if (this.state.selectedStation) {                
                this.getMoreInfoStation(this.state.selectedStation!!);
            }
    }

 
        
    componentDidMount = () => {
     
      const dashboard = window.location.search.includes('dashboard');
      const portal = window.location.search.includes('portal');
      this.setState({ dashboardView: dashboard }); 
      this.setState({ portalView: portal }); 
      this.getLocation();
      this.getStringsFromServer();
      this.getDataFromServer();
        
    }

    getStringsFromServer = () => {
        const STRINGS_URL = config.SERVER_URL + "ChargingSystemPortal/api/localization/" + this.getAPI_browserLang();

        //URL below should be in a global constant, 
        // even better would be to have it come from 
        // some configuration somewhere
        axios.get(STRINGS_URL)
            .then(response => {
                console.info("GOLBAL_URL_CELLOCHARGE", response.data[0]);
                this.setState({ stringsFromServer: response.data[0] })
            })
            .catch(err => {
                //Do we need to let the user know there was a problem? Maybe not?
                console.error(err);
            })
    }

    getDataFromServer = () => {
        const GOLBAL_URL_CELLOCHARGE = config.SERVER_URL + "ChargingSystemPortal/api/";
        
        const stationDataURL = GOLBAL_URL_CELLOCHARGE + this.getAPI_browserLang();
        axios.get(stationDataURL)
            .then(response => {
                //If you have logging that you plan on keeping - console.info(), console.warn(), console.debug(), 
                console.info("response", response);                              
                this.setState({ stationData: response.data, filterStationData: response.data }, () => {
                    const moreInfoStationDataURL = GOLBAL_URL_CELLOCHARGE + "stations/filters";

                    axios.get(moreInfoStationDataURL)
                        //might want to use a different variable name other than 'response'
                        // so as not to worry about variable masking
                        .then(response => {
                            console.info("moreInfoStationDataURL", response);
                            this.updateMoreInfoStationData(response.data);
                            
                        })
                        .catch(err => {
                            //Do we need to let the user know there was a problem? Maybe not?
                            console.error(err);
                        })
                })
            })
            .catch(err => {
                //Do we need to let the user know there was a problem? Maybe not?
                console.error(err);
            })


        // const stationData: AddressInfo[] = exampleAPI_Json;
        // const moreInfoStationData: MoreInfoStationData[] = exampleFilter_Json;
        // this.setState({ stationData }, () => this.updateMoreInfoStationData(moreInfoStationData))
    }

    getDistanceFromUser() {
        if (!this.state.userLocation) return
        const { filterStationData, userLocation } = this.state;
        const userLat = userLocation.lat;
        const userLng = userLocation.lng;
        filterStationData.forEach(addressInfo => {
            addressInfo.distanceFromUser = this.calcCrow(userLat, userLng, addressInfo.lat, addressInfo.lng);
        })
        filterStationData.sort((a: AddressInfo, b: AddressInfo) => (a.distanceFromUser > b.distanceFromUser) ? 1 : -1);
        this.setState({ filterStationData })
    }

    setCluster = () => {
        const { longitudeArrLarge, latitudeArrLarge } = this.setClusterLarge();
        const { longitudeArSmall, latitudeArrSmall } = this.setClusterSmall();
        this.setState({
            clusterArrLarge: this.sortClusters(longitudeArrLarge, latitudeArrLarge),
            clusterArrSmall: this.sortClusters(longitudeArSmall, latitudeArrSmall)
        });
    }

    sortClusters = (longitudeArr: AddressInfo[][], latitudeArr: AddressInfo[][]): Cluster[] => {
        let clusterArr: Cluster[] = [];
        longitudeArr.forEach(longArrItem => {
            if (longArrItem.length < 1) return;
            latitudeArr.forEach(latArrItem => {
                if (latArrItem.length < 1) return;
                const latIdArr = latArrItem.map(latItem => latItem.id);
                const relevantPoints = longArrItem.filter(item => latIdArr.includes(item.id));
                if (relevantPoints.length === 0) return;
                const cluster: Cluster = {
                    coordinates: {
                        lng: relevantPoints[0].lng,
                        lat: relevantPoints[0].lat,
                    },
                    counter: relevantPoints.length
                }
                if (cluster.counter > 0) {
                    clusterArr.push(cluster);
                }
            })
        })
        return (clusterArr);
    }

    setClusterLarge = () => {
        const sixthLongitude = (EAST_POINT - WEST_POINT) / 6;
        const onePartsFifteenLatitude = (NORTH_POINT - SOUTH_POINT) / 15;

        let longitudeArr: AddressInfo[][] = [];
        longitudeArr[0] = this.state.filterStationData.filter(station => station.lng > (EAST_POINT - (sixthLongitude * 2)));
        longitudeArr[1] = this.state.filterStationData.filter(station => station.lng < (EAST_POINT - (sixthLongitude * 2)) && station.lng > (EAST_POINT - (sixthLongitude * 3)));
        longitudeArr[2] = this.state.filterStationData.filter(station => station.lng < (EAST_POINT - (sixthLongitude * 3)) && station.lng > (EAST_POINT - (sixthLongitude * 4)));
        longitudeArr[3] = this.state.filterStationData.filter(station => station.lng < (EAST_POINT - (sixthLongitude * 4)) && station.lng > (EAST_POINT - (sixthLongitude * 5)));
        longitudeArr[4] = this.state.filterStationData.filter(station => station.lng < (EAST_POINT - (sixthLongitude * 5)) && station.lng > (EAST_POINT - (sixthLongitude * 6)));
        longitudeArr[5] = this.state.filterStationData.filter(station => station.lng < (EAST_POINT - (sixthLongitude * 6)));

        let latitudeArr: AddressInfo[][] = [];
        latitudeArr[0] = this.state.filterStationData.filter(station => station.lat > (NORTH_POINT - (onePartsFifteenLatitude * 2)));
        latitudeArr[1] = this.state.filterStationData.filter(station => station.lat < (NORTH_POINT - (onePartsFifteenLatitude * 2)) && station.lat > (NORTH_POINT - (onePartsFifteenLatitude * 3)));
        latitudeArr[2] = this.state.filterStationData.filter(station => station.lat < (NORTH_POINT - (onePartsFifteenLatitude * 3)) && station.lat > (NORTH_POINT - (onePartsFifteenLatitude * 4)));
        latitudeArr[3] = this.state.filterStationData.filter(station => station.lat < (NORTH_POINT - (onePartsFifteenLatitude * 4)) && station.lat > (NORTH_POINT - (onePartsFifteenLatitude * 5)));
        latitudeArr[4] = this.state.filterStationData.filter(station => station.lat < (NORTH_POINT - (onePartsFifteenLatitude * 5)) && station.lat > (NORTH_POINT - (onePartsFifteenLatitude * 6)));
        latitudeArr[5] = this.state.filterStationData.filter(station => station.lat < (NORTH_POINT - (onePartsFifteenLatitude * 6)) && station.lat > (NORTH_POINT - (onePartsFifteenLatitude * 7)));
        latitudeArr[6] = this.state.filterStationData.filter(station => station.lat < (NORTH_POINT - (onePartsFifteenLatitude * 7)) && station.lat > (NORTH_POINT - (onePartsFifteenLatitude * 8)));
        latitudeArr[7] = this.state.filterStationData.filter(station => station.lat < (NORTH_POINT - (onePartsFifteenLatitude * 8)) && station.lat > (NORTH_POINT - (onePartsFifteenLatitude * 9)));
        latitudeArr[8] = this.state.filterStationData.filter(station => station.lat < (NORTH_POINT - (onePartsFifteenLatitude * 9)) && station.lat > (NORTH_POINT - (onePartsFifteenLatitude * 10)));
        latitudeArr[9] = this.state.filterStationData.filter(station => station.lat < (NORTH_POINT - (onePartsFifteenLatitude * 10)) && station.lat > (NORTH_POINT - (onePartsFifteenLatitude * 11)));
        latitudeArr[10] = this.state.filterStationData.filter(station => station.lat < (NORTH_POINT - (onePartsFifteenLatitude * 11)) && station.lat > (NORTH_POINT - (onePartsFifteenLatitude * 12)));
        latitudeArr[11] = this.state.filterStationData.filter(station => station.lat < (NORTH_POINT - (onePartsFifteenLatitude * 12)) && station.lat > (NORTH_POINT - (onePartsFifteenLatitude * 13)));
        latitudeArr[12] = this.state.filterStationData.filter(station => station.lat < (NORTH_POINT - (onePartsFifteenLatitude * 13)) && station.lat > (NORTH_POINT - (onePartsFifteenLatitude * 14)));
        latitudeArr[13] = this.state.filterStationData.filter(station => station.lat < (NORTH_POINT - (onePartsFifteenLatitude * 14)) && station.lat > (NORTH_POINT - (onePartsFifteenLatitude * 15)));
        latitudeArr[14] = this.state.filterStationData.filter(station => station.lat < (NORTH_POINT - (onePartsFifteenLatitude * 15)));

        return ({
            longitudeArrLarge: longitudeArr,
            latitudeArrLarge: latitudeArr
        })
    }

    setClusterSmall = () => {
        const thirdLongitude = (EAST_POINT - WEST_POINT) / 3;
        const fifthLatitude = (NORTH_POINT - SOUTH_POINT) / 5;

        let longitudeArr: AddressInfo[][] = [];
        longitudeArr[0] = this.state.filterStationData.filter(station => station.lng > (EAST_POINT - (thirdLongitude * 2)));
        longitudeArr[1] = this.state.filterStationData.filter(station => station.lng < (EAST_POINT - (thirdLongitude * 2)) && station.lng > (EAST_POINT - (thirdLongitude * 3)));
        longitudeArr[2] = this.state.filterStationData.filter(station => station.lng < (EAST_POINT - (thirdLongitude * 3)))

        let latitudeArr: AddressInfo[][] = [];
        latitudeArr[0] = this.state.filterStationData.filter(station => station.lat > (NORTH_POINT - (fifthLatitude * 2)));
        latitudeArr[1] = this.state.filterStationData.filter(station => station.lat < (NORTH_POINT - (fifthLatitude * 2)) && station.lat > (NORTH_POINT - (fifthLatitude * 3)));
        latitudeArr[2] = this.state.filterStationData.filter(station => station.lat < (NORTH_POINT - (fifthLatitude * 3)) && station.lat > (NORTH_POINT - (fifthLatitude * 4)));
        latitudeArr[3] = this.state.filterStationData.filter(station => station.lat < (NORTH_POINT - (fifthLatitude * 4)) && station.lat > (NORTH_POINT - (fifthLatitude * 5)));
        latitudeArr[4] = this.state.filterStationData.filter(station => station.lat < (NORTH_POINT - (fifthLatitude * 5)))

        return ({
            longitudeArSmall: longitudeArr,
            latitudeArrSmall: latitudeArr
        })
    }


    render() {
        
         return (
            <div className="map-page">
                 {!this.state.dashboardView && 
                <div className="header">
                    <Header
                        browserLanguage={this.state.lang}
                        stringsFromServer={this.state.stringsFromServer}
                        addressInfoData={this.state.filterStationData}
                        setSelectedStationCoordinates={this.setSelectedStationCoordinates}
                        filterPopup={this.openFilterPopup}
                        layersPopup={this.layersPopup}
                        closeAllPopupsFunc={this.closeAllPopupsFunc}
                        changeLanguage={this.changeLanguage}
                        userLocation={this.state.userLocation}
                    >
                    </Header>
                </div>
    }
                {!this.state.dashboardView && 
                <Filter
                    filterPopup={this.openFilterPopup}
                    changeSelectedConnector={this.changeSelectedConnector}
                    stringsFromServer={this.state.stringsFromServer}
                    selectedConnectorsArr={this.state.selectedConnectorsArr}
                    operatorsArr={this.state.operatorsArr}
                    changeSelectedOprators={this.changeSelectedOperators}
                    paymentsArr={this.state.paymentsArr}
                    changeSelectedPayment={this.changeSelectedPayment}
                    includeArr={this.state.includeArr}
                    changeSelectedInclude={this.changeSelectedInclude}
                    browserLanguage={this.state.lang}
                    openFilterPopup={this.state.openFilterPopup}
                    handleChangePower={this.handleChangePower}
                    handleSocketsNumber={this.handleSocketsNumber}
                    socektNumber={this.state.socektNumber}
                    powerSelected={this.state.powerSelected}
                    layersPopup={this.layersPopup}
                ></Filter>
                }
                {!this.state.dashboardView && 
                <Layers
                    browserLanguage={this.state.lang}
                    layersPopup={this.layersPopup}
                    openLayersPopup={this.state.openLayersPopup}
                    availableHandle={this.availableHandle}
                    availableFilter={this.state.availableFilter}
                    inactiveHandle={this.inactiveHandle}
                    stringsFromServer={this.state.stringsFromServer}
                    inactiveFilter={this.state.inactiveFilter}
                    busyHandle={this.busyHandle}
                    busyFilter={this.state.busyFilter}
                    comingSoonHandle={this.comingSoonHandle}
                    comingSoonFilter={this.state.comingSoonFilter}
                ></Layers>
                 }
                  {!this.state.dashboardView && 
                <MoreInfo
                    moreInfoPopup={this.moreInfoPopup}
                    openMoreInfoPopup={this.state.openMoreInfoPopup}
                    stringsFromServer={this.state.stringsFromServer}
                    browserLanguage={this.state.lang}
                    moreInfoData={this.state.moreInfoData}
                ></MoreInfo>
                  }
                <div className='google-map-react'>
                <div className={`cellopark-logo ${this.state.lang}`}>
                        <img
                            src={cellopark_logo}
                            alt="cellopark logo"
                        ></img>
                    </div>
                    <GoogleMapReact
                        //Put the key in an different constants file 
                        
                        onChange={(value: any) => this.getNewBoundsCord(value)}
                        bootstrapURLKeys={{ key: 'AIzaSyAu8rMVaM6AMyrOG6zwMZ-bY5m2DSTaj7I', language: this.state.lang }}
                        center={this.state.defaultCenter}
                        zoom={this.state.mapZoom}
                        defaultZoom={this.state.mapZoom}
                        yesIWantToUseGoogleMapApiInternals
                        options={{ gestureHandling: 'greedy', clickableIcons: false, disableDefaultUI: true, minZoom: MIN_ZOOM, zoomControl: true, 
                                    zoomControlOptions: { position: this.getZoomPosition() }}}
                    >
                     { this.state.showIcons ? this.getStationsInBounds().map((addressInfoElement: AddressInfo) =>
                             <IconMap
                                 key={addressInfoElement.id}
                                 addressInfo={addressInfoElement}
                                 getMoreInfoStation={this.getMoreInfoStation}
                                 lat={addressInfoElement.lat}
                                 lng={addressInfoElement.lng}
                                 closeAllPopups={this.state.closeAllPopups}
                                 selectedStation={this.state.selectedStation}
                                 browserLanguage={this.state.lang}
                                 userLocation={this.state.userLocation}
                                 onClickStation={this.onClickStation}
                                 stringsFromServer={this.state.stringsFromServer}
                                 moreInfoData={this.state.moreInfoData}                                 
                             ></IconMap>

                         ) :
                             this.getClusterMap()
                         }                     
                                                                                                             
                                 <Marker 
                                 lat={this.state.userLocation?.lat ? this.state.userLocation?.lat.toString() : "3"}
                                 lng={this.state.userLocation?.lng ? this.state.userLocation?.lng.toString() : "4"}
                                 text={"Current Location"}
                                  >    
                                      {/* <img style={markerStyle} src={pin} alt="pin" /> */}
                                 </Marker>
                        
                    </GoogleMapReact>
                </div>
                <div className={`go-to-my-location ${this.state.lang}`}>
                        <img
                            src={go_to_my_location}
                            alt="go to my location"
                            onClick={this.goToMyLocation}
                        ></img>
                    </div>
                {!this.state.dashboardView && !this.state.portalView &&  
                <Footer
                    stringsFromServer={this.state.stringsFromServer}
                    closeAllPopupsFunc={this.closeAllPopupsFunc}
                    browserLanguage={this.state.lang}                    
                ></Footer>    
                 }                   
            </div>
          
        )
       
    }
    

    getClusterMap(): JSX.Element[] {        
        const clusters = this.state.mapZoom < toggleZoomIconClusterSmall ? this.state.clusterArrSmall : this.state.clusterArrLarge;
        const clusterMap = clusters.map((cluster, index) =>
            <ClusterMap
                key={index}
                lat={cluster.coordinates.lat}
                lng={cluster.coordinates.lng}
                counter={cluster.counter}
                mapZoom={this.state.mapZoom}
                clickCluster={this.clickCluster}
            >
            </ClusterMap>
        )
        return (clusterMap)
    }



    // Converts numeric degrees to radians
    toRad(Value: number) {
        return Value * Math.PI / 180;
    }

    calcCrow(lat1: number, lon1: number, lat2: number, lon2: number) {
        var R = 6371; // km
        var dLat = this.toRad(lat2 - lat1);
        var dLon = this.toRad(lon2 - lon1);
        var lat1 = this.toRad(lat1);
        var lat2 = this.toRad(lat2);

        var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c;
        return d;
    }

    getNewBoundsCord(value: any) {
       
        if (!value || !value.bounds || !value.center || !value.zoom) return;
        const showIcons = value.zoom < toggleZoomIconClusterLarge ? false : true;
        const selectedStation = !showIcons ? undefined : this.state.selectedStation;
        this.setState({ boundsCord: value.bounds, centerOfMap: value.center, mapZoom: value.zoom, showIcons, selectedStation })
        console.log("value", value)
    }

    getStationsInBounds(): AddressInfo[] {
        let { filterStationData, boundsCord, selectedStation } = this.state;
        filterStationData = filterStationData.filter(station => {
            return (
                station.lat < boundsCord.ne.lat &&
                station.lat > boundsCord.se.lat &&
                station.lng < boundsCord.ne.lng &&
                station.lng > boundsCord.sw.lng 
                && !station.moreInfoStationData?.hide
            )
        })
        if (selectedStation && !filterStationData.find(station => station.id === selectedStation?.id)) {
            filterStationData.push(selectedStation);
        }
        return (filterStationData)
    }


    getAPI_browserLang(): number {
        const langStorage = this.getLanguage();
        switch (langStorage) {
            case (BrowserLanguage.en):
                return (1);
            case (BrowserLanguage.he):
                return (2);
            case (BrowserLanguage.ar):
                return (3);
            case (BrowserLanguage.ru):
                return (4);
            default:
                return (2);
        }
    }

    updateMoreInfoStationData = (moreInfoStationDataArr: MoreInfoStationData[]) => {
        let stationData = this.state.stationData;
        stationData.forEach((addressInfo: AddressInfo) => {
            addressInfo.moreInfoStationData = moreInfoStationDataArr.find((moreInfoStationData: MoreInfoStationData) =>
                moreInfoStationData.stationID === addressInfo.id)
        })
        let filteredStationData = stationData.filter(x => {            
            if (x.moreInfoStationData?.hide == false) {
                return x;
            }
        });
        this.setState({ stationData, filterStationData: filteredStationData }, () => {
            this.getDistanceFromUser();
            this.filterStations();
            
        });
    }

    clickCluster = (coordinates: Coordinates) => {
        console.log("coordinates", coordinates);
        const newMapZoom = this.state.mapZoom < toggleZoomIconClusterSmall ? toggleZoomIconClusterSmall : 13;
        this.setState({ defaultCenter: coordinates, mapZoom: newMapZoom });
    }

    setSelectedStationCoordinates = (searchOption: SearchOption) => {
        const findStation = this.state.stationData
            .find((addressInfoElement: AddressInfo) => addressInfoElement.id === searchOption?.id)
        if (!findStation) {
            return;
        }

    
        const defaultCenter = { lat: findStation.lat, lng: findStation.lng }
        this.setState({
            defaultCenter,
            mapZoom: CLOSEUP_ZOOM_LEVEL,
            selectedStation: findStation,
            closeAllPopups: true,
            openMoreInfoPopup: false,
            openLayersPopup: false,
            openFilterPopup: false,
        },()=>this.registerNavigation(14));
       
    }

    closeAllPopupsFunc = () => {
        if (this.state.closeAllPopups !== true || this.state.selectedStation !== undefined) {
            this.setState({ closeAllPopups: true, selectedStation: undefined });
        }
    }

    getLanguage = (): BrowserLanguage => {
        const langStorage = sessionStorage.getItem(lang);
          
        if (langStorage) {
            return (langStorage as BrowserLanguage);
        }

        const lang_browser = navigator.language;
        if (lang_browser == BrowserLanguage.he || lang_browser === BrowserLanguage.ar || lang_browser === BrowserLanguage.en || lang_browser === BrowserLanguage.ru) {
            return (lang_browser);
        }
        //otherwise
        return (BrowserLanguage.he);
    }
    changeLanguage = (language: BrowserLanguage) => {
        console.log(lang, language);
        this.getLocation();
        sessionStorage.setItem(lang, language);
        
        window.location.reload();
       
        //this.InitValues();
       
    }

    getLocation() {
        //alert('1');
         if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.showPosition, this.onGeolocationForbidden);
        } else {
            //Do we need to let the user know there was a problem? Maybe not?
            console.warn('Google Maps does not have permission to use your location.')
        }
    }

    showPosition = (position: any) => {
        const userLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
        }
        
        this.setState({ userLocation, defaultCenter: userLocation, centerOfMap: userLocation, mapZoom: CLOSEUP_ZOOM_LEVEL },()=>this.registerNavigation(11));               
    }

    onGeolocationForbidden = () => {
        //Do we need to let the user know there was a problem? Maybe not?
        
        console.warn('Google Maps does not have permission to use your location.')
    }

    onClickStation = (addressInfo: AddressInfo) => {
        if(!this.state.dashboardView)
        {
            this.setState({
                selectedStation: addressInfo,
                closeAllPopups: true,
                openFilterPopup: false,
                openLayersPopup: false,
                openMoreInfoPopup: false,
            
            },()=>this.registerNavigation(12));
            return;
        }
        return;
    }

    getMoreInfoStation = (addressInfo: AddressInfo) => {
        console.log('getMoreInfoStation', addressInfo);
        //externalize
        // const url = "https://cellocharge.com/backend/ChargingSystemPortal/api/stations/" + addressInfo.refId + '/' + this.getAPI_browserLang();;
        // const url = "https://localhost:44307/ChargingSystemPortal/api/stations/" + addressInfo.refId + '/' + this.getAPI_browserLang();
        const url = config.SERVER_URL + "ChargingSystemPortal/api/stations/" + addressInfo.refId + '/' + this.getAPI_browserLang();
            
        //this.setState({ moreInfoData: undefined});

        axios.get(url)
            .then(response => {
                console.log("response station", response);
                //this.moreInfoPopup();                
                this.setState({ moreInfoData: response.data[0], closeAllPopups: false, selectedStation: undefined },()=>this.registerNavigation(13));
            
            })
            .catch(err => {
                //Do we need to let the user know there was a problem? Maybe not?
                console.error(err); //console.error
                alert(err);

            })
    }

    openFilterPopup = () => {
        this.setState({ openFilterPopup: !this.state.openFilterPopup, openLayersPopup: false, openMoreInfoPopup: false })
    }

    changeSelectedConnector = (selectedSocket: string, value: boolean) => {
        let selectedConnectorsArr = this.state.selectedConnectorsArr;

        if (selectedConnectorsArr.find(socket => socket === allTypesConnectors) && selectedSocket !== allTypesConnectors) {
            selectedConnectorsArr = selectedConnectorsArr.filter(item => item !== allTypesConnectors);
            selectedConnectorsArr.push(selectedSocket);
            this.setState({ selectedConnectorsArr }, () => this.filterStations());
            return;
        }
        if (selectedSocket === allTypesConnectors && value) {
            selectedConnectorsArr = [allTypesConnectors];
            this.setState({ selectedConnectorsArr }, () => this.filterStations());
            return
        }
        if (value) {
            selectedConnectorsArr.push(selectedSocket);
            this.setState({ selectedConnectorsArr }, () => this.filterStations());
            return;
        }

        selectedConnectorsArr = selectedConnectorsArr.filter(item => item !== selectedSocket);
        this.setState({ selectedConnectorsArr }, () => this.filterStations());
    }

    changeSelectedOperators = (selectedOperator: number, value: boolean) => {
        let operatorsArr = this.state.operatorsArr;

        if (operatorsArr.find(operator => operator === allTypesOperators) && selectedOperator !== allTypesOperators) {
            operatorsArr = operatorsArr.filter(item => item !== allTypesOperators);
            operatorsArr.push(selectedOperator);
            this.setState({ operatorsArr }, () => this.filterStations());
            return;
        }
        if (selectedOperator === allTypesOperators && value) {
            operatorsArr = [allTypesOperators];
            this.setState({ operatorsArr }, () => this.filterStations());
            return
        }

        if (value) {
            operatorsArr.push(selectedOperator);
            this.setState({ operatorsArr }, () => this.filterStations());
            return;
        }
        operatorsArr = operatorsArr.filter(item => item !== selectedOperator);
        this.setState({ operatorsArr }, () => this.filterStations());
    }

    changeSelectedPayment = (selectedPayment: string, value: boolean) => {
        let paymentsArr = this.state.paymentsArr;

        if (paymentsArr.find(payment => payment === allTypesPayment) && selectedPayment !== allTypesPayment) {
            paymentsArr = paymentsArr.filter(item => item !== allTypesPayment);
            paymentsArr.push(selectedPayment);
            this.setState({ paymentsArr }, () => this.filterStations());
            return;
        }
        if (selectedPayment === allTypesPayment && value) {
            paymentsArr = [allTypesPayment];
            this.setState({ paymentsArr }, () => this.filterStations());
            return
        }

        if (value) {
            paymentsArr.push(selectedPayment);
            this.setState({ paymentsArr }, () => this.filterStations());
            return;
        }
        paymentsArr = paymentsArr.filter(item => item !== selectedPayment);
        this.setState({ paymentsArr }, () => this.filterStations());
    }

    changeSelectedInclude = (include: string, value: boolean) => {
        let includeArr = this.state.includeArr;

        if (includeArr.find(inc => inc === allTypesIncludes) && include !== allTypesIncludes) {
            includeArr = includeArr.filter(item => item !== allTypesIncludes);
            includeArr.push(include);
            this.setState({ includeArr }, () => this.filterStations());
            return;
        }

        if (include === allTypesIncludes && value) {
            includeArr = [allTypesIncludes]
            this.setState({ includeArr }, () => this.filterStations());
        }

        if (value) {
            includeArr.push(include);
            this.setState({ includeArr }, () => this.filterStations());
            return;
        }

        includeArr = includeArr.filter(item => item !== include);
        this.setState({ includeArr }, () => this.filterStations());
    }


    handleChangePower = (powerSelected: number) => {
        //Needs a comment here to explain what numbers you're converting from
        // on what numbers you're converting to. (Or use an enum)
        switch (powerSelected) {
            case (5):
            case (10):
                break;
            case (15):
                powerSelected = 20;
                break;
            case (20):
                powerSelected = 40;
                break;
            case (25):
                powerSelected = 100;
                break;
            case (30):
                powerSelected = 350;
                break;

        }
        this.setState({ powerSelected }, () => this.filterStations())
    }

    handleSocketsNumber = (socektNumber: number) => {
        this.setState({ socektNumber }, () => this.filterStations());
    }

    filterStations = () => {
        const {
            powerSelected,
            socektNumber,
            operatorsArr,
            paymentsArr,
            stationData,
            selectedConnectorsArr,
            includeArr,
        } = this.state;

        let filterStationData = this.filterLayersOption().filter(station => {
            const moreInfoStationData = station.moreInfoStationData;
            if (!moreInfoStationData) return (false);
            if ((moreInfoStationData.maxPower >= powerSelected
                // ||moreInfoStationData.minPower <= powerSelected
            ) &&
                moreInfoStationData.connectorCounter >= socektNumber &&
                (selectedConnectorsArr.includes(allTypesConnectors) || selectedConnectorsArr.some(con => moreInfoStationData.connectorTypesArr.includes(con))) &&
                (operatorsArr.includes(allTypesOperators) || operatorsArr.includes(station.provID)) &&
                (paymentsArr.includes(allTypesPayment) || paymentsArr.some(pay => moreInfoStationData.paymentTypesArr.includes(pay))) &&
                (includeArr.includes(allTypesIncludes) || includeArr.some(include => moreInfoStationData.serviceTypesArr.includes(include)))
            )
                return (true);
            return (false);
        })
        this.setState({ filterStationData }, () => this.setCluster());

    }

    layersPopup = () => {
        this.closeAllPopupsFunc()
        this.setState({ openLayersPopup: !this.state.openLayersPopup, openFilterPopup: false, openMoreInfoPopup: false })
    }

    moreInfoPopup = () => {
        this.setState({ openMoreInfoPopup: !this.state.openMoreInfoPopup, openFilterPopup: false, openLayersPopup: false })
    }


    availableHandle = () => {
        console.log("this.state.availableFilter", !this.state.availableFilter)
        this.setState({ availableFilter: !this.state.availableFilter }, () => this.filterStations());
    }

    inactiveHandle = () => {
        console.log("this.state.inactiveFilter", !this.state.inactiveFilter)
        this.setState({ inactiveFilter: !this.state.inactiveFilter }, () => this.filterStations());
    }

    busyHandle = () => {
        console.log("this.state.busyFilter", !this.state.busyFilter)
        this.setState({ busyFilter: !this.state.busyFilter }, () => this.filterStations());
    }

    comingSoonHandle = () => {
        console.log("this.state.comingSoonFilter", !this.state.comingSoonFilter)
        this.setState({ comingSoonFilter: !this.state.comingSoonFilter }, () => this.filterStations());
    }

    filterLayersOption = (): AddressInfo[] => {
        let filterStationData = this.state.stationData;
        filterStationData = filterStationData.filter((station: AddressInfo) => {
            switch (station.status) {
                case (LayersFilters.AVAILABLE):
                    return (this.state.availableFilter);
                case (LayersFilters.BUSY):
                    return (this.state.busyFilter);
                case (LayersFilters.INACTIVE):
                    return (this.state.inactiveFilter);
                case (LayersFilters.COMING_SOON):
                    return (this.state.comingSoonFilter);
                default:
                    return (true);
            }
        })
        return (filterStationData)
    }

    goToMyLocation = () => {
        navigator.permissions.query({ name: 'geolocation' }).then(x=> {
         if(x.state != 'denied') {
            this.setState({defaultCenter: {lat: this.state.centerOfMap.lat, lng: this.state.centerOfMap.lng}});
            this.getLocation();
         } else {
            alert("יש לאשר הרשאות מיקום");
         }
        });
     }

     getZoomPosition() {
        let result = 9;
         const lang = this.getAPI_browserLang();
        if (lang == 2 || lang == 3) {
            result = 6;
        }
        return result;
     }
}

interface MapState {
    defaultCenter: Coordinates;    
    selectedStation?: AddressInfo;
    mapZoom: number;
    point: string;
    lang: BrowserLanguage;
    stationData: AddressInfo[];
    filterStationData: AddressInfo[];
    closeAllPopups: boolean;
    openFilterPopup: boolean;
    openLayersPopup: boolean;
    openMoreInfoPopup: boolean;
    userLocation?: Coordinates;
    powerSelected: number;
    socektNumber: number;
    selectedConnectorsArr: string[];
    operatorsArr: number[];
    paymentsArr: string[];
    includeArr: string[];
    availableFilter: boolean;
    inactiveFilter: boolean;
    busyFilter: boolean; //typo: busyFilter
    comingSoonFilter: boolean;
    moreInfoData?: MoreInfoData;
    stringsFromServer: StringsData;
    boundsCord: BoundsCord;
    centerOfMap: Coordinates;
    clusterArrLarge: Cluster[];
    clusterArrSmall: Cluster[];
    showIcons: boolean;
    userActivity:string;
    dashboardView:boolean;
    portalView:boolean;

}
interface MapProps {
    // browserLanguage:string
    //test
}

export default Map;