export interface StringsData {
    siteSubjectId: number;
    site_AccessbillityText: string;
    site_ContactText: string;
    site_ContactUsCarNum: string;
    site_ContactUsContext: string;
    site_ContactUsEmail: string;
    site_ContactUsName: string;
    site_ContactUsPhone: string;
    site_ContactUsSubTitle: string;
    site_ContactUsSubject: string;
    site_ContactUsTitle: string;
    site_FilterByConnType: string;
    site_FilterByInclude: string;
    site_FilterByOperators: string;
    site_FilterByPayment: string;
    site_FilterByPower: string;
    site_FilterBySocketsNum: string;
    site_FilterTitle: string;
    site_LayesTitle: string;
    site_MainHeader: string;
    site_SearchText: string;
    site_TermsOfUse: string;
    site_FilterLoading: string;
    site_FilterParking: string;
    site_FilterPayByCard: string;
    site_FilterPayByCash: string;
    site_FilterPayByPhone: string;
    site_FilterPayFree: string;
    site_FilterRestroom: string;
    site_FilterShopping: string;
    site_FilterWifi: string;
    site_LayersAvailable: string;
    site_LayersAvailableText: string;
    site_LayersBusy: string;
    site_LayersComingSoon: string;
    site_LayersComingSoonText: string;
    site_LayersInactive: string;
    site_ContactUsAppDesc: string;
    site_ContactUsBadRespMsg: string;
    site_ContactUsRespMsg: string;
    site_ContactUsSend: string;
    site_ContactUsSendigMsg: string;
    site_FilterAll: string;
    site_LayersBusyTxt: string;
    site_LayersNotActiveTxt: string;
    site_StationInfoAddress: string;
    site_StationInfoFastCharge: string;
    site_StationInfoMoreInfoTxt: string;
    site_StationInfoNavigate: string;
    site_StationInfoRegularCharge: string;
    site_StationInfoPrices:string;
}

export class StringsDataDefault {
    readonly siteSubjectId = 1;
    readonly site_AccessbillityText = "נגישות";
    readonly site_ContactText = "צור קשר";
    readonly site_ContactUsAppDesc = "תיאור היישום";
    readonly site_ContactUsBadRespMsg = "שליחת ההודעה נכשלה";
    readonly site_ContactUsCarNum = "מספר רכב";
    readonly site_ContactUsContext = "פרטי הפניה";
    readonly site_ContactUsEmail = "אימייל";
    readonly site_ContactUsName = "שם";
    readonly site_ContactUsPhone = "מספר טלפון";
    readonly site_ContactUsRespMsg = "ההודעה נשלחה בהצלחה";
    readonly site_ContactUsSend = "שליחה";
    readonly site_ContactUsSendigMsg = "ההודעה נשלחת..";
    readonly site_ContactUsSubTitle = "נציג סלופארק יצור קשר בהקדם";
    readonly site_ContactUsSubject = "נושא הפניה";
    readonly site_ContactUsTitle = "כתבו לנו";
    readonly site_FilterAll = "הכל";
    readonly site_FilterByConnType = "סוג חיבור";
    readonly site_FilterByInclude = "שירותים נוספים";
    readonly site_FilterByOperators = "ספקים";
    readonly site_FilterByPayment = "אפשרויות תשלום";
    readonly site_FilterByPower = "הספק טעינה";
    readonly site_FilterBySocketsNum = "כמות שקעים";
    readonly site_FilterLoading = "לינה";
    readonly site_FilterParking = "חניה";
    readonly site_FilterPayByCard = "אשראי";
    readonly site_FilterPayByCash = "מזומן";
    readonly site_FilterPayByPhone = "טלפון נייד";
    readonly site_FilterPayFree = "חינם";
    readonly site_FilterRestroom = "נוחיות";
    readonly site_FilterShopping = "קניות";
    readonly site_FilterTitle = "מסנן";
    readonly site_FilterWifi = "WiFi";
    readonly site_LayersAvailable = "זמין";
    readonly site_LayersAvailableText = "תחנות פנויות לטעינה";
    readonly site_LayersBusy = "עמוס";
    readonly site_LayersBusyTxt = "תחנות תפוסות כרגע";
    readonly site_LayersComingSoon = "ייפתח בקרוב";
    readonly site_LayersComingSoonText = "תחנות שצפויות לקום בקרוב";
    readonly site_LayersInactive = "לא פעיל";
    readonly site_LayersNotActiveTxt = "תחנות פגומות או בתחזוקה";
    readonly site_LayesTitle = "שכבות";
    readonly site_MainHeader = "מפת עמדות הטענה";
    readonly site_SearchText = "חיפוש תחנות טעינה";
    readonly site_StationInfoAddress = "כתובת התחנה";
    readonly site_StationInfoFastCharge = "כמות טעינה מהירה";
    readonly site_StationInfoMoreInfoTxt = "מידע נוסף";
    readonly site_StationInfoNavigate = "ניווט לתחנה";
    readonly site_StationInfoRegularCharge = "כמות טעינה רגילה";
    readonly site_TermsOfUse = "תנאי שימוש";
    readonly site_StationInfoPrices = "מחירים";

}