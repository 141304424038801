import { Component } from 'react';
import { AddressInfo, ConnectorType, Coordinates, ProviderConsts } from '../../entities/iconData';
import station_2_icon from '../../assets/images/station_2_icon.png';
import busy_station_icon from '../../assets/images/busy_station_icon.png';
import unknown_station_icon from '../../assets/images/unknown_status_icon.png'
import inactive_station_icon from '../../assets/images/inactive_station_icon.png';
import comingSoon_station_icon from '../../assets/images/comingSoon_station_icon.png';
import afkon from '../../assets/Providers/afkon.png';
import charge from '../../assets/Providers/charge.png';
import greenspot from '../../assets/Providers/greenspot.png';
import sonol from '../../assets/Providers/sonol.png';
import netzer from '../../assets/Providers/netzer.png';
import Ev4u from '../../assets/Providers/ev4u.png';
import EdgeControl from '../../assets/Providers/edge-control.png';
import ScalaEv from '../../assets/Providers/scalaev.png';
import ViMore from '../../assets/Providers/vimore.png';
import Nofar from '../../assets/Providers/nofar.png';
import Greems from '../../assets/Providers/greems.png';
import TDSD from '../../assets/Providers/tdsd.png';
import SevenEv from '../../assets/Providers/sevenev.png';
import Doralurban from '../../assets/Providers/doral-urban.png';
import Lishatech from '../../assets/Providers/lishatech.png';

import ZenEv from '../../assets/Providers/zenev.png';
import Enova from '../../assets/Providers/enova.png';
import InterEv from '../../assets/Providers/interev.png';

import info_icon from '../../assets/images/info_icon.png';
import gnrgy from '../../assets/operator_logo/Gnrgy.png';
import evedge from '../../assets/operator_logo/EvEgde_Logo.png';
import evcharge from '../../assets/operator_logo/EVCharge_Logo.png';
import go_to_icon from '../../assets/images/go_to_icon.png';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles, Theme } from '@material-ui/core/styles';
import './IconMap.scss'
import CloseIcon from '@material-ui/icons/Close';
import { BrowserLanguage } from '../../entities/BrowserLanguage';
import { LayersFilters } from '../../entities/LayersFilters';
import { StringsData } from '../../entities/StringsData';
import { type } from 'os';
import gmap from '../../assets/images/google-maps-icon.svg.png';
import wazeIcon from '../../assets/images/waze-icon.png';

import { ReactComponent as Type_2_img } from '../../assets/Sockets/type_2_icon.svg';
import { ReactComponent as Combo_icon } from '../../assets/Sockets/combo_icon.svg';
import { ReactComponent as Phase_icon } from '../../assets/Sockets/phase_icon.svg';
import { ReactComponent as Schuko_icon } from '../../assets/Sockets/schuko_icon.svg';
import { MoreInfoData, StationConnector } from '../../entities/MoreInfoData';
import { Box, Tab, Tabs } from '@material-ui/core';
import { TabContext, TabPanel } from '@material-ui/lab';

const popup = 'popup';
const tooltip = 'tooltip';
class IconMap extends Component<IconMapProps, IconMapState> {

    constructor(props: IconMapProps) {
        super(props);
        this.state = {
            open: false,
            tooltipType: tooltip,
            portalView:false,
            tabValue: '0',            
        }
    }

    render() {
        const { addressInfo, stringsFromServer } = this.props;
        if (this.state.tooltipType === tooltip) {
            return (
                <div
                    className="tooltip"
                    key={addressInfo.id}
                >
                    <this.LightTooltip
                        placement="top"
                        arrow
                        title={<div className='tooltip-label'>{addressInfo.name}</div>}
                    >
                        <img
                            className='location-map'
                            src={this.getCorrectStationIcon()}
                            alt="station icon"
                            onClick={this.clickIcon}
                        >
                        </img>
                    </this.LightTooltip>
                </div>
            )
        }
        return (
            <div>
                <this.LightTooltip        
                    placement="top"
                    interactive
                    arrow
                    title={
                        <div className={`popup-icon ${this.props.browserLanguage}`}>
                                 <div className="img-popup-icon">
                                <span className="close-icon"
                                    onClick={this.handleTooltipClose}
                                >
                                    <CloseIcon />
                                </span>
                            </div>
                            <Box sx={{ width: '100%' }}>
                                <TabContext value='0'>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs value={this.state.tabValue} onChange={this.InfoTabValueChanged} aria-label="station info tabs">
                                    <Tab id='0' value={'0'} label="כללי" aria-label="general info" key='0' />
                                    <Tab id='1' value={'1'} label="תעריפים" aria-label="tariffs info" key='1' />          
                                    </Tabs>
                                </Box>
                                <TabPanel value={this.state.tabValue} key={'0'}>
                                <div className="go-to">
                                    <img src={wazeIcon} alt="waze icon" onClick={() => this.wazeNavigation(addressInfo)}/>
                                    <img src={gmap} alt="google maps icon" onClick={() => this.googleMapsNavigation(addressInfo)} />
                                
                                </div> 
                                <div className="provider-logo">
                                    <img className="img-provider" src={this.getProviderImage(addressInfo.provID)} alt="" />
                                </div>
                           
                           <div className="name">
                                <span className="label">
                                    {this.getStationName(addressInfo.name)}
                                </span>
                            </div>

                            {/* <hr></hr> */}
                            <div className="street">
                                <div className="address-label">
                                   {/*  {stringsFromServer.site_StationInfoAddress}*/}
                                </div>
                                <div className="address-info-div">
                                    {addressInfo.streetName}
                                </div>
                            </div>
                            <div className="connector-num">
                                {this.updatecConnectorTypes().map(connector => {
                                    return (
                                        <div className="power-num-img">
                                            <div>{this.getConnectorName(connector.connectorType.toString())}</div>
                                            <div className="connector-img">
                                                <span className="">
                                                    {this.getConnectorImage(connector.connectorType.toString())}
                                                     <label className="circle">
                                                        {connector.counter }
                                                    </label>
                                                </span>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                                </TabPanel>
                                </TabContext>
                                <TabContext value='1'>
                                <TabPanel value={this.state.tabValue} key={'1'}>
                                    <div className='tariff-info'>
                                        <span className='tariff-info-span' dir='rtl'>{!!addressInfo.priceDesc ? addressInfo.priceDesc : "אין מידע"}</span>
                                    </div>
                                </TabPanel>                    
                                </TabContext>
                             </Box>                       
                       
{/* 
                            <div className="chargers">
                               
                                 { (addressInfo.moreInfoStationData && (addressInfo.moreInfoStationData.fastConnectorCounter>0))?
                                    <span className='label' > <span className="circle"></span>
                                 {    ' DC - ' + addressInfo.moreInfoStationData.fastConnectorCounter } <br /></span>:''} 
                                   {/*{stringsFromServer.site_StationInfoFastCharge}*/}
                                 {/* { (addressInfo.moreInfoStationData && ((addressInfo.moreInfoStationData.connectorCounter - addressInfo.moreInfoStationData.fastConnectorCounter) >0))? 
                                     <span className='label'>
                                      <span className="circle"></span>
                                {' AC - ' + (addressInfo.moreInfoStationData.connectorCounter- addressInfo.moreInfoStationData.fastConnectorCounter)}</span> :''}  */}
                                  {/*{this.getRegularChargeConnectors(addressInfo)} {stringsFromServer.site_StationInfoRegularCharge}*/}
                            {/* </div> */} 
                            {/* <div className="button">
                                <div className="more-info-button"
                                    onClick={() => this.props.getMoreInfoStation(addressInfo)}
                                >
                                    <img src={info_icon} alt="mor info icon" />
                                    <span className="label">{stringsFromServer.site_StationInfoMoreInfoTxt} </span>
                                </div>
                        
                            </div> */}
                        </div>
                    }
                    onClose={this.handleTooltipClose}
                    open={this.state.open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                >
                    <img
                        className='location-map-click'
                        src={this.getCorrectStationIcon()}
                        alt="station icon"
                        onClick={this.clickIcon}
                    >
                    </img>
                </this.LightTooltip>
            </div>
        )
    }

    LightTooltip = withStyles((theme: Theme) => ({
        tooltip: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            fontSize: 15,
            width: 'auto',
            padding: '0',
            border: '1px solid #0071F2',
            borderRadius: '15px'            
        },
    }))(Tooltip);

    componentDidMount() {
        const portal = window.location.search.includes('dashboard');
        this.setState({ portalView: portal }); 
        this.checkSelectedStation();
    }


    componentDidUpdate(prevProps: IconMapProps) {
        const { selectedStation, closeAllPopups } = this.props
        if (
            selectedStation &&
            prevProps.selectedStation?.id !== selectedStation.id) {
            this.checkSelectedStation();
        }
        if (!selectedStation && closeAllPopups && this.state.open) {
            this.setState({ open: false, tooltipType: tooltip })

        }
    }

    getStationName = (name: string) => {
        if (name.length > 28) {
            name = name.slice(0, 25) + '...';
        }
        return (name);
    }

    checkSelectedStation = () => {
        const { addressInfo, selectedStation, closeAllPopups } = this.props
        if (addressInfo.id === selectedStation?.id && !this.state.portalView) {
            this.setState({ open: true, tooltipType: popup })
            return;
        }
        if (closeAllPopups) {
            this.setState({ open: false, tooltipType: tooltip })
        }
    }


    handleTooltipClose = () => {
        this.setState({ open: false, tooltipType: tooltip })
    };

    clickIcon = () => {
        if (this.state.tooltipType === tooltip && !this.state.portalView) {
            this.setState({ open: true, tooltipType: popup });
            setTimeout(
                () => this.props.onClickStation(this.props.addressInfo)
                , 0
            )
            return;
        }
        this.setState({ open: false, tooltipType: tooltip });

    }

    getRegularChargeConnectors = (addressInfo:AddressInfo) =>
    {
        var cntr:number = 0;
        cntr = addressInfo.moreInfoStationData? (addressInfo.moreInfoStationData.connectorCounter - addressInfo.moreInfoStationData.fastConnectorCounter):0;
        // addressInfo.moreInfoStationData?.connectorTypes?.filter(con=>con.power>=50).map(typ=>cntr++);
        // console.log("addressInfo.moreInfoStationData", addressInfo);
         return(cntr);
    }

    getProviderImage = (providerId: number) => {
        const providerConsts = new ProviderConsts();

        switch (providerId) {
            case providerConsts.PROVIDER_AFKON: {
                return (afkon)
            }
            case providerConsts.PROVIDER_GNRGY: {
                return (gnrgy)
            }
            case providerConsts.PROVIDER_CHARGE: {
                return (evcharge)
            }
            case providerConsts.PROVIDER_GREENSPOT: {
                return (greenspot)
            }
            case providerConsts.PROVIDER_SONOL: {
                return (sonol)
            }
            case providerConsts.PROVIDER_NETZER: {
                return (netzer)
            }
            case providerConsts.PROVIDER_EV4U: {
                return (Ev4u)
            }
            case providerConsts.PROVIDER_EDGE_CONTROL: {
                return (EdgeControl)
            }
            case providerConsts.PROVIDER_SCALAEV: {
                return (ScalaEv)
            }
            case providerConsts.PROVIDER_VIMORE: {
                return (ViMore)
            }
            case providerConsts.PROVIDER_NOFAR: {
                return (Nofar)
            }
            case providerConsts.PROVIDER_GREEMS: {
                return (Greems)
            }
            case providerConsts.PROVIDER_TDSD: {
                return (TDSD)
            }
            case providerConsts.PROVIDER_SEVENEV: {
                return (SevenEv)
            }
            case providerConsts.PROVIDER_DORALURBAN: {
                return (Doralurban)
            }
            case providerConsts.PROVIDER_LISHATECH: {
                return (Lishatech)
            }
            case providerConsts.PROVIDER_ZENEV: {
                return (ZenEv)
            }
            case providerConsts.PROVIDER_ENOVA: {
                return (Enova)
            }
            case providerConsts.PROVIDER_INTEREV: {
                return (InterEv)
            }
            case providerConsts.PROVIDER_EVEGDE: {
                return (evedge)
            }
            default: {
                return (charge)
            }
        }

    }

    //Generally a good idea to move third-party integration into its own file, 
    // even if you just include it here with an additional require. 
    wazeNavigation = (addressInfo: AddressInfo) => {
        console.log('addressInfo', addressInfo);
        const wazeBaseUrl = "https://www.waze.com/";
        const wazeLanguage = this.getWazeLanguage();
        const wazeTo = 'to=ll.' + addressInfo.lat + '%2C' + addressInfo.lng;
        const wazeFrom = !this.props.userLocation ? '' : "&from=ll." + this.props.userLocation.lat + "%2C" + this.props.userLocation.lng;
        const wazeURL = wazeBaseUrl + wazeLanguage + "live-map/directions?" + wazeTo + wazeFrom;
        window.open(wazeURL, "_blank")
    }

    getWazeLanguage(): string {
        switch (this.props.browserLanguage) {
            case ('ru'):
            case ('ar'):
            case ('he'): {
                return (this.props.browserLanguage + '/');
            }
            case ('en-US'):
            default: {
                return ('');
            }
        }
    }

    googleMapsNavigation = (addressInfo: AddressInfo) => {
        const From = !this.props.userLocation ? '' : this.props.userLocation.lat + "%2C" + this.props.userLocation.lng;
         const googleMapsUrl = "https://www.google.com/maps/dir/" + From + "/" + addressInfo.lat + "%2C" + addressInfo.lng;

         window.open(googleMapsUrl, "_blank")
    }

    getCorrectStationIcon(): string {
        const { addressInfo } = this.props;
        switch (addressInfo.status) {
            case (LayersFilters.UNKNOWN):
                return (unknown_station_icon);
            case (LayersFilters.AVAILABLE):
                return (station_2_icon);
            case (LayersFilters.BUSY):
                return (busy_station_icon);
            case (LayersFilters.INACTIVE):
                return (inactive_station_icon);
            case (LayersFilters.COMING_SOON):
                return (comingSoon_station_icon);
            default:
                return (station_2_icon);

        }
    }

    getConnectorImage = (connectorNumber: string): JSX.Element => {
        const connectorType = new ConnectorType();
        const COMBO = connectorType.COMBO;
        const PHASE = connectorType.PHASE;
        const TYPE_2 = connectorType.TYPE_2;
        const SCHUKO = connectorType.SCHUKO;

        switch (connectorNumber) {
            case (COMBO):
                return (<Combo_icon />);
            case (PHASE):
                return (<Phase_icon />);
            case (TYPE_2):
                return (<Type_2_img />);
            case (SCHUKO):
                return (<Schuko_icon />);
            default:
                return (<Combo_icon />)
        }
    }

    getConnectorName = (connectorNumber: string): string => {
        const connectorType = new ConnectorType();
        const COMBO = connectorType.COMBO;
        const PHASE = connectorType.PHASE;
        const TYPE_2 = connectorType.TYPE_2;
        const SCHUKO = connectorType.SCHUKO;

        switch (connectorNumber) {
            case (COMBO):
                return ("combo");
            case (PHASE):
                return ("pahse");
            case (TYPE_2):
                return ("type2");
            case (SCHUKO):
                return ("schuko");
            default:
                return ('combo');
        }
    }

    
    updatecConnectorTypes(): StationConnector[] {
        const connectorTypeClass = new ConnectorType();
        const { moreInfoData } = this.props;
        let combo = moreInfoData?.connectorTypes.filter(connect => connect.connectorType.toString() === connectorTypeClass.COMBO);
        let phase = moreInfoData?.connectorTypes.filter(connect => connect.connectorType.toString() === connectorTypeClass.PHASE);
        let type_2 = moreInfoData?.connectorTypes.filter(connect => connect.connectorType.toString() === connectorTypeClass.TYPE_2);
        let schuko = moreInfoData?.connectorTypes.filter(connect => connect.connectorType.toString() === connectorTypeClass.SCHUKO);
        let CHAdeMO = moreInfoData?.connectorTypes.filter(connect => connect.connectorType.toString() === connectorTypeClass.CHADEMO);
        let other = moreInfoData?.connectorTypes.filter(connect => connect.connectorType.toString() === connectorTypeClass.OTHER);
        
       
        let connectorTypes: StationConnector[] = [];
        if (combo && combo.length > 0) {
            let cntr = 0;
            combo.map(cmb=>cntr += cmb.connectorCounter);
            combo[0].counter = cntr;
            connectorTypes.push(combo[0])
        }
        if (phase && phase.length > 0) {
            let cntr = 0;
            phase.map(cmb=>cntr += cmb.connectorCounter);
            phase[0].counter = cntr;
           // phase[0].counter = phase.length;
            connectorTypes.push(phase[0])
        }
        if (type_2 && type_2.length > 0) {
            let cntr = 0;
            type_2.map(cmb=>cntr += cmb.connectorCounter);
            type_2[0].counter = cntr;
           // type_2[0].counter = type_2.length;
            connectorTypes.push(type_2[0])
        }
        if (schuko && schuko.length > 0) {
            let cntr = 0;
            schuko.map(cmb=>cntr += cmb.connectorCounter);
            schuko[0].counter = cntr;
           // schuko[0].counter = schuko.length;
            connectorTypes.push(schuko[0])
        }
        if (CHAdeMO && CHAdeMO.length > 0) {
            let cntr = 0;
            CHAdeMO.map(cmb=>cntr += cmb.connectorCounter);
            CHAdeMO[0].counter = cntr;
           // schuko[0].counter = schuko.length;
            connectorTypes.push(CHAdeMO[0])
        }
        if (other && other.length > 0) {
            let cntr = 0;
            other.map(cmb=>cntr += cmb.connectorCounter);
            other[0].counter = cntr;
           // schuko[0].counter = schuko.length;
            connectorTypes.push(other[0])
        }
        return connectorTypes;
    }

    InfoTabValueChanged = (event: React.ChangeEvent<{}>, newValue: string) : void => {
        console.log(newValue);
        this.setState({ tabValue: newValue});
      }
}

interface IconMapProps {
    lat: number;
    lng: number;
    addressInfo: AddressInfo;
    getMoreInfoStation: Function;
    closeAllPopups: boolean,
    selectedStation?: AddressInfo,
    browserLanguage: BrowserLanguage;
    userLocation?: Coordinates;
    onClickStation: Function
    stringsFromServer: StringsData;
    moreInfoData?: MoreInfoData;
}

interface IconMapState {
    tooltipType: 'tooltip' | 'popup',
    open: boolean;
    selectedAddressInfo?: AddressInfo;
    portalView:boolean;
    tabValue: string;
}

export default IconMap
