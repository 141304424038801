import { Component } from 'react';
import Popup from '../Popup/Popup';
import './Footer.scss';
import cellopark_logo from '../../assets/images/logo-V-01.png'
import contact_us_icon from '../../assets/images/contact_us_icon.png'
import office_logo from '../../assets/images/office_logo.png'
import { PopupType } from '../../entities/PopupType';
import { BrowserLanguage } from '../../entities/BrowserLanguage';
import { StringsData } from '../../entities/StringsData';

class Footer extends Component<FooterProps, FooterState> {

    constructor(props: FooterProps) {
        super(props);
        this.state = {
            openPopup: false,
            popupType: PopupType.EMPTY,

        }
    }
    //registered company symbol instead of @, maybe?
    render() {
        return (
            <footer className= {`footer ${this.props.browserLanguage}`}>
                {/* <div className={`cellopark ${this.props.browserLanguage}`}
                    onClick={() => window.open("https://site.cellopark.co.il/")}
                > */}
                <div className={`center ${this.props.browserLanguage}`}>
                    {/* <div className="cellopark-logo">
                        <img
                            src={cellopark_logo}
                            alt="cellopark logo"
                        ></img>
                    </div> */}
                    {/* <div className="powered footer-text">
                        {this.getCelloStr()}
                    </div>                 */}
                 <div className="office-logo"> {/* <img
                        src={office_logo}
                        alt="office logo"
                    ></img>*/}
                </div> 
                
                    <div className="terms footer-text"
                        onClick={() => this.openPopup(PopupType.TERMS_OF_USE)}
                    >
                        {this.props.stringsFromServer.site_TermsOfUse}
                    </div> 
                    <div className="accessibillty footer-text"
                        onClick={() => this.openPopup(PopupType.ACCESSIBILITY_STATMENT)}
                    >
                        {this.props.stringsFromServer.site_AccessbillityText}
                    </div>

                    <div className={`contact-us ${this.props.browserLanguage}`}
                        onClick={() => this.openPopup(PopupType.POPUP_CONTACT)}
                    >
                        {/* <img
                            src={contact_us_icon}
                            alt="contact us icon"
                        ></img> */}
                        <span className="footer-text">
                            {this.props.stringsFromServer.site_ContactText}
                        </span>
                    </div>
                </div>
                {/* </div> */}
                {this.state.openPopup &&
                    <Popup
                        stringsFromServer={this.props.stringsFromServer}
                        openPopup={this.state.openPopup}
                        closePopup={this.closePopup}
                        popupType={this.state.popupType}
                        browserLanguage={this.props.browserLanguage}
                    ></Popup>
                }
            </footer>
        )
    }

    getCelloStr (): JSX.Element {
        
        switch (this.props.browserLanguage) {
            case BrowserLanguage.en: {
                return (<div > Powered by Cello in collaboration with the Ministry of Energy</div>);
            }
            case BrowserLanguage.he: {
                return (<div>מופעל ע"י Cello בשיתוף עם משרד האנרגיה</div>);
            }
            case BrowserLanguage.ru: {
                return (<div>Работает на Cello в сотрудничестве Министерством энергетики</div>);
            }
            case BrowserLanguage.ar: {
                return (<div>تديرها شركة Cello بالتعاون مع وزارة الطاقة</div>);
            }
            default: {
                return (<div>Powered by Cello  <br/> in collaboration with the Ministry of Energ'</div>);
            }
        }
    } 

    openPopup = (popupType: PopupType) => {
        this.setState({ openPopup: true, popupType });
        this.props.closeAllPopupsFunc();
    }

    closePopup = () => {
        this.setState({ openPopup: false })
    }

}
export default Footer

interface FooterProps {
    browserLanguage: BrowserLanguage;
    stringsFromServer: StringsData;
    closeAllPopupsFunc: Function;
}
interface FooterState {
    openPopup: boolean;
    popupType: PopupType;

}