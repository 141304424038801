import React, { Component } from 'react';
import './Filter.scss';
import CloseIcon from '@material-ui/icons/Close';
import Slider from '@material-ui/core/Slider';
import { ReactComponent as Type_2_img } from '../../assets/Sockets/type_2_icon.svg';
import { ReactComponent as Combo_icon } from '../../assets/Sockets/combo_icon.svg';
import { ReactComponent as Phase_icon } from '../../assets/Sockets/phase_icon.svg';
import { ReactComponent as Schuko_icon } from '../../assets/Sockets/schuko_icon.svg';
import { ReactComponent as By_card_icon } from '../../assets/payment_options/by_card_icon.svg';
import { ReactComponent as By_cash_icon } from '../../assets/payment_options/by_cash_icon.svg';
import { ReactComponent as By_phone_icon } from '../../assets/payment_options/by_phone_icon.svg';
import { ReactComponent as Free_icon } from '../../assets/payment_options/free_icon.svg';
import { ReactComponent as Wifi_icon } from '../../assets/Incloud/wifi_icon.svg';
import { ReactComponent as Loading_icon } from '../../assets/Incloud/loading_icon.svg';
import { ReactComponent as Parking_icon } from '../../assets/Incloud/parking_icon.svg';
import { ReactComponent as Restoom_icon } from '../../assets/Incloud/restoom_icon.svg';
import { ReactComponent as Shopping_icon } from '../../assets/Incloud/shopping_icon.svg';

import { ReactComponent as Charging_power_icon } from '../../assets/images//charging_power_icon.svg';
import { ReactComponent as Connection_type_icon } from '../../assets/images//connection_type_icon.svg';
import { ReactComponent as Include_filter_icon } from '../../assets/images//include_filter_icon.svg';
import { ReactComponent as Operators_filter_icon } from '../../assets/images//operators_filter_icon.svg';
import { ReactComponent as Payments_filter_icon } from '../../assets/images//payments_filter_icon.svg';
import { ReactComponent as Socket_icon } from '../../assets/images//socket_icon.svg';
import { ReactComponent as Layers_icon } from '../../assets/images/layers_icon.svg';

// import charging_power_icon from '../../assets/images/charging_power_icon.png';
// import connection_type_icon from '../../assets/images/connection_type_icon.png';
// import include_filter_icon from '../../assets/images/include_filter_icon.png';
// import operators_filter_icon from '../../assets/images/operators_filter_icon.png';
// import payments_filter_icon from '../../assets/images/payments_filter_icon.png';
// import socket_icon from '../../assets/images/socket_icon.png';
import Afkon_Logo from '../../assets/operator_logo/Afkon_Logo.png';
import EvEgde_Logo from '../../assets/operator_logo/EvEgde_Logo.png';
import EVCharge_Logo from '../../assets/operator_logo/EVCharge_Logo.png';
import Gnrgy_img from '../../assets/operator_logo/Gnrgy.png';
import GreenSpot_Logo from '../../assets/operator_logo/GreenSpot_Logo.png';
import Sonol_Logo from '../../assets/operator_logo/Sonol_Logo.png';
import Netzer_Logo from '../../assets/operator_logo/netzer.png';
import Ev4u_Logo from '../../assets/operator_logo/ev4u.png';
import EdgeControl_Logo from '../../assets/operator_logo/edge-control.png';
import ScalaEv_Logo from '../../assets/operator_logo/scalaev.png';
import ViMore_Logo from '../../assets/operator_logo/vimore.png';
import Nofar_Logo from '../../assets/operator_logo/nofar.png';
import Greems_Logo from '../../assets/operator_logo/greems.png';
import TDSD_Logo from '../../assets/operator_logo/tdsd.png';
import SevenEv_Logo from '../../assets/operator_logo/sevenev.png';
import Lishatech_Logo from '../../assets/operator_logo/lishatech.png';
import Doralurban_Logo from '../../assets/operator_logo/doral-urban.png';

import zenev_Logo from '../../assets/operator_logo/zenev.png';
import enova_Logo from '../../assets/operator_logo/enova.png';
import interev_Logo from '../../assets/operator_logo/interev.png';

import { ProviderConsts, ConstClass, ConnectorType, PriceType, IncludesType } from '../../entities/iconData';
import { BrowserLanguage } from '../../entities/BrowserLanguage';
import filter_icon from '../../assets/images/filter_icon.png';
import filter_icon_full_color from '../../assets/images/filter_icon_full_color.png';
import station_1_icon from '../../assets/images/station_1_icon.png';
import { ReactComponent as All_img_svg } from '../../assets/images/all_img_svg.svg';
import ReactTooltip from 'react-tooltip';
import { StringsData } from '../../entities/StringsData';
import Layers from '../Layers/Layers';

const allTypesClass = new ConstClass();
const allTypesConnectors = allTypesClass.allTypesConnectors;
const allTypesOperators = allTypesClass.allTypesOperators;
const allTypesPayment = allTypesClass.allTypesPayment;
const allTypesIncludes = allTypesClass.allTypesIncludes;

const changePowerMarks = [
    {
        value: 5,
        label: '5'  ,
    },
    {
        value: 10,
        label: '10' ,
    },
    {
        value: 15,
        label: '20',
    },
    {
        value: 20,
        label: '40',
    },
    {
        value: 25,
        label: '220',
    },
    {
        value: 30,
        label: '350',
    },

];
const socketsNumberMarks = [
    {
        value: 1,
        label: '1',
    },
    {
        value: 2,
        label: '2',
    },
    {
        value: 3,
        label: '3',
    },
    {
        value: 4,
        label: '4',
    },
    {
        value: 5,
        label: '5',
    },
    {
        value: 6,
        label: '5>',
    },

];



class Filter extends Component<FilterProps, FilterState>{

    constructor(props: FilterProps) {
        super(props);
        
        this.state = {
            chargingPowerOpen: false,
            socketsNumberOpen: false,
            connectionTypeOpen: false,
            operatorsOpen: false,
            paymentsOpen: false,
            includeOpen: false,
        }
        this.props.handleChangePower(1 as number); 
        this.props.handleSocketsNumber(1 as number);
        changePowerMarks.map(s=>s.label=s.label+ this.getPowerUnitStr());
        socketsNumberMarks.map(s=>s.label= s.value===6? this.getSocketNumberMoreStr():s.label);
        
    }



    render() {
        
        return (
            <div className={this.getPopupClassName()}>
                <span className={`close-icon-popup-filter ${this.props.browserLanguage}`}
                    onClick={() => {
                        this.props.filterPopup();
                        this.setState({
                            chargingPowerOpen: false,
                            socketsNumberOpen: false,
                            connectionTypeOpen: false,
                            operatorsOpen: false,
                            paymentsOpen: false,
                            includeOpen: false,
                        })
                    }}
                >
                    <CloseIcon />
                </span>
                {this.props.openFilterPopup ?
                    this.getPopupElement() :
                    this.getPopupImages()
                }
                {this.props.openFilterPopup && (this.props.selectedConnectorsArr[0] !== allTypesConnectors ||
this.props.operatorsArr[0] !== allTypesOperators || this.props.paymentsArr[0] !== allTypesPayment || 
    this.props.socektNumber !== 1 || this.props.powerSelected !==1) && 
                 <span className={`clear-filter-text ${this.props.browserLanguage}`}
                    onClick={() =>{ this.props.handleChangePower(1 as number); 
                        this.props.handleSocketsNumber(1 as number);
                        //this.props.changeSelectedConnector();
                        this.props.selectedConnectorsArr[0] = allTypesConnectors;
                        this.props.operatorsArr[0] = allTypesOperators;
                        this.props.paymentsArr[0] = allTypesPayment;
                        this.setState({
                            connectionTypeOpen: false,
                            chargingPowerOpen: false,
                            socketsNumberOpen: false,
                            operatorsOpen: false,
                            paymentsOpen: false,
                            includeOpen: false,
                        })
                    }}>
                       
                        {this.getClearFiltersStr()}
                       
                    </span>
    }      
            </div>
        )

    }
    
    getPowerUnitStr(): string  {
        switch (this.props.browserLanguage) {
            case BrowserLanguage.en: {
                return ('kW');
            }
            case BrowserLanguage.he: {
                return ('קו"ט');
            }
            case BrowserLanguage.ru: {
                return ('kW');
            }
            case BrowserLanguage.ar: {
                return ('kW');
            }
            default: {
                return ('kW');
            }
        }
    }
    getSocketNumberMoreStr(): string  {
        switch (this.props.browserLanguage) {
            case BrowserLanguage.en: {
                return ('More');
            }
            case BrowserLanguage.he: {
                return ('עוד');
            }
            case BrowserLanguage.ru: {
                return ('Более');
            }
            case BrowserLanguage.ar: {
                return ('أكثر');
            }
            default: {
                return ('More');
            }
        }
    }

    getClearFiltersStr(): string  {
        switch (this.props.browserLanguage) {
            case BrowserLanguage.en: {
                return ('Clear filters');
            }
            case BrowserLanguage.he: {
                return ('איפוס מסננים');
            }
            case BrowserLanguage.ru: {
                return ('Clear filters');
            }
            case BrowserLanguage.ar: {
                return ('Clear filters');
            }
            default: {
                return ('Clear filters');
            }
        }
    }

    getPopupClassName(): string {
        const { browserLanguage, openFilterPopup } = this.props;

        if (!openFilterPopup && (browserLanguage === BrowserLanguage.he || browserLanguage === BrowserLanguage.ar)) {
            return ('not-show-filter-popup-he');
        }
        if (!openFilterPopup) {
            return ('not-show-filter-popup-en');
        }
        if (browserLanguage === BrowserLanguage.he || browserLanguage === BrowserLanguage.ar) {
            return ('filter-popup-he');
        }
        return ('filter-popup-en');
    }

    getPopupImages = (): JSX.Element => {
        return (
            <div className="filter-popup-close">
                {this.getPopupTitleFilter(false)}
                {this.getChangePowerImage()}
                {this.getSocketsNumberImage()}
                {this.getConnectionTypeImage()}
                {this.getOperatorTypeImage()}
                {this.getLayersImage()}
                {/* {this.getPaymentsTypeImage()} */}
                {/* {this.getIncludeTypeImage()} */}
            </div>
        )
    }

    getPopupElement = (): JSX.Element => {
        return (
            <div className="filter-popup">
                {this.getPopupTitleFilter(true)}
                {this.getChangePowerElement()}
                {this.getSocketsNumberElement()}
                {this.getConnectionTypeElement()}
                {this.getOperatorsElement()}
                {/* {this.getPaymentsElement()} */}
                {/* {this.getIncludeElement()} */}
            </div>
        )
    }

    getPopupTitleFilter = (open: boolean) => {
        return (
            <div className="">
                <div className="title-filter"
                    onClick={() => {
                        this.props.filterPopup();
                        this.setState({
                            chargingPowerOpen: false,
                            socketsNumberOpen: false,
                            connectionTypeOpen: false,
                            operatorsOpen: false,
                            paymentsOpen: false,
                            includeOpen: false,
                        })
                    }}                >
                    <span className="title-img">
                        <img src={open ? filter_icon_full_color : filter_icon} alt="filter icon" />
                    </span>
                    <span className="title-text">{this.props.stringsFromServer.site_FilterTitle}</span>
                </div>
            </div>
        )
    }

    getChangePowerImage = (): JSX.Element => {
        return (
            <div className="icon-and-tooltip" onClick={() => { this.props.filterPopup(); this.setState({ chargingPowerOpen: true }) }}>
                <div className="img-change-power  filter-popup-icon" data-tip data-for='img-change-power'>
                    {/* <img src={charging_power_icon} alt="charging power icon" /> */}
                    <span className='fill-color'>
                        <Charging_power_icon />
                    </span>
                </div>
                <ReactTooltip id='img-change-power'
                    type='light'
                    effect="solid"
                    border
                    place={(this.props.browserLanguage === BrowserLanguage.he || this.props.browserLanguage === BrowserLanguage.ar) ? 'left' : 'right'}
                >
                    <span className="icon-filter-tooltip">{this.props.stringsFromServer.site_FilterByPower}</span>
                </ReactTooltip>
            </div>
        )
    }

    getChangePowerElement = (): JSX.Element => {
        const filter_popup_icon_class_name = this.state.chargingPowerOpen ? 'filter-popup-icon-open' : 'filter-popup-icon';
        const arrowClassName = this.state.chargingPowerOpen ? 'arrow-top' : 'arrow-bottom';
        //Charging_power_icon
        return (
            <div className="charging-power">
                <div className={filter_popup_icon_class_name}
                    onClick={() => this.setState({
                        chargingPowerOpen: !this.state.chargingPowerOpen,
                        socketsNumberOpen: false,
                        connectionTypeOpen: false,
                        operatorsOpen: false,
                        paymentsOpen: false,
                        includeOpen: false,
                    })}
                >
                    {/* <img src={charging_power_icon} alt="charging power icon" /> */}
                    <span className='fill-color'>
                        <Charging_power_icon />
                    </span>
                    <span className="charging-power-title">
                        {this.props.stringsFromServer.site_FilterByPower}
                    </span>
                    <span className={arrowClassName}></span>
                </div>
                <div className="charging-power-slider">
                    {this.state.chargingPowerOpen &&
                        <div className="slider-filter">
                            <Slider
                                aria-label="Custom marks"
                                defaultValue={this.props.powerSelected}
                                onChange={(event: React.ChangeEvent<any>, newValue: number | number[]) => this.props.handleChangePower(newValue as number)}
                                step={5}
                                marks={changePowerMarks}
                                min={5}
                                max={25}
                                track='inverted'
                            />
                            {/* <div>{ this.getPowerUnitStr() }</div> */}
                        </div>
                        
                    }
                </div>
            </div>
        )
    }

    getSocketsNumberImage = (): JSX.Element => {
        return (
            <div className="icon-and-tooltip" onClick={() => { this.props.filterPopup(); this.setState({ socketsNumberOpen: true }) }}>
                <div className="img-socket-number filter-popup-icon" data-tip data-for='img-socket-number'>
                    {/* <img src={socket_icon} alt="socket icon" /> */}
                    <span >
                        <Socket_icon />
                    </span>
                </div>
                <ReactTooltip id='img-socket-number'
                    type='light'
                    effect="solid"
                    border
                    place={(this.props.browserLanguage === BrowserLanguage.he || this.props.browserLanguage === BrowserLanguage.ar) ? 'left' : 'right'}
                >
                    <span className="icon-filter-tooltip">{this.props.stringsFromServer.site_FilterBySocketsNum}</span>
                </ReactTooltip>
            </div>
        )
    }


    getSocketsNumberElement = (): JSX.Element => {
        const filter_popup_icon_class_name = this.state.socketsNumberOpen ? 'filter-popup-icon-open' : 'filter-popup-icon';
        const arrowClassName = this.state.socketsNumberOpen ? 'arrow-top' : 'arrow-bottom';
        return (
            <div className="sockets-number">
                <div className={filter_popup_icon_class_name}
                    onClick={() => this.setState({
                        socketsNumberOpen: !this.state.socketsNumberOpen,
                        chargingPowerOpen: false,
                        connectionTypeOpen: false,
                        operatorsOpen: false,
                        paymentsOpen: false,
                        includeOpen: false,
                    })}
                >
                    {/* <img src={socket_icon} alt="socket icon" /> */}
                    <span >
                        <Socket_icon />
                    </span>
                    <span className="sockets-number-title">
                        {this.props.stringsFromServer.site_FilterBySocketsNum}
                    </span>
                    <span className={arrowClassName}></span>
                </div>
                <div className="sockets-number-slider">
                    {this.state.socketsNumberOpen &&
                        <div className="slider-filter">
                            <Slider
                                aria-label="Temperature"
                                defaultValue={this.props.socektNumber}
                                onChange={(event: React.ChangeEvent<any>, newValue: number | number[]) => this.props.handleSocketsNumber(newValue as number)}
                                step={1}
                                marks={socketsNumberMarks}
                                min={1}
                                max={6}
                                track='inverted'
                            />
                        </div>
                    }
                </div>
            </div>
        )
    }

    getConnectionTypeImage = (): JSX.Element => {
        return (
            <div className="icon-and-tooltip" onClick={() => { this.props.filterPopup(); this.setState({ connectionTypeOpen: true }) }}>
                <div className="img-connection-type filter-popup-icon" data-tip data-for='img-connection-type'>
                    {/* <img src={connection_type_icon} alt="connection type icon" /> */}
                    <span className='fill-color'>
                        <Connection_type_icon />
                    </span>
                </div>
                <ReactTooltip id='img-connection-type'
                    type='light'
                    effect="solid"
                    border
                    place={(this.props.browserLanguage === BrowserLanguage.he
                        || this.props.browserLanguage === BrowserLanguage.ar) ? 'left' : 'right'}
                >
                    <span className="icon-filter-tooltip">{this.props.stringsFromServer.site_FilterByConnType}</span>
                </ReactTooltip>
            </div>
        )
    }

    getConnectionTypeElement = (): JSX.Element => {
        const connectorType = new ConnectorType();
        const COMBO = connectorType.COMBO;
        const PHASE = connectorType.PHASE;
        const TYPE_2 = connectorType.TYPE_2;
        const SCHUKO = connectorType.SCHUKO;
        const filter_popup_icon_class_name = this.state.connectionTypeOpen ? 'filter-popup-icon-open' : 'filter-popup-icon';
        const arrowClassName = this.state.connectionTypeOpen ? 'arrow-top' : 'arrow-bottom';
        return (
            <div>
                <div className={filter_popup_icon_class_name}
                    onClick={() => this.setState({
                        connectionTypeOpen: !this.state.connectionTypeOpen,
                        chargingPowerOpen: false,
                        socketsNumberOpen: false,
                        operatorsOpen: false,
                        paymentsOpen: false,
                        includeOpen: false,
                    })}
                >
                    {/* <img src={connection_type_icon} alt="connection type icon" /> */}
                    <span className='fill-color' >
                        <Connection_type_icon />
                    </span>
                    <span className="connection-type-title">
                        {this.props.stringsFromServer.site_FilterByConnType}
                    </span>
                    <span className={arrowClassName}></span>
                </div>


                <div className="connection-type-checkbox">
                    {this.state.connectionTypeOpen &&
                        <div className="">
                            <div className="all-checkbox">
                                <input type="checkbox" id="allConnection" name="allConnection" value={allTypesConnectors} checked={this.props.selectedConnectorsArr.find(socket => socket === allTypesConnectors) ? true : false}
                                    onChange={(e: React.FormEvent<HTMLInputElement>) => this.props.changeSelectedConnector(allTypesConnectors, e.currentTarget.checked)}
                                />
                                <label className='all-label type-checkbox-label' htmlFor="allConnection">
                                    <span className="type-checkbox-logo">
                                        <All_img_svg />
                                    </span>
                                </label>
                                <div className="text-image">{this.props.stringsFromServer.site_FilterAll} </div>
                            </div>

                            <div className="type_2-checkbox">
                                <input type="checkbox" id="type_2" name="type_2" value={TYPE_2} checked={this.props.selectedConnectorsArr.find(socket => socket === TYPE_2 || socket === allTypesConnectors) ? true : false}
                                    onChange={(e: React.FormEvent<HTMLInputElement>) => this.props.changeSelectedConnector(TYPE_2, e.currentTarget.checked)}
                                />
                                <label className='type-2-label type-checkbox-label' htmlFor="type_2">
                                    <span className="type-checkbox-logo">
                                        <Type_2_img />
                                    </span>
                                </label>
                                <div className="text-image">type 2 </div>
                            </div>

                            <div className="combo-checkbox">
                                <input type="checkbox" id="combo" name="combo" value={COMBO} checked={this.props.selectedConnectorsArr.find(socket => socket === COMBO || socket === allTypesConnectors) !== undefined ? true : false}
                                    onChange={(e: React.FormEvent<HTMLInputElement>) => this.props.changeSelectedConnector(COMBO, e.currentTarget.checked)}
                                />
                                <label className='combo-label type-checkbox-label' htmlFor="combo">
                                    <span className="type-checkbox-logo">
                                        <Combo_icon />
                                    </span>                        </label>
                                <div className="text-image">combo </div>
                            </div>

                            <div className="schuko-checkbox">
                                <input type="checkbox" id="schuko" name="schuko" value={SCHUKO} checked={this.props.selectedConnectorsArr.find(socket => socket === SCHUKO || socket === allTypesConnectors) ? true : false}
                                    onChange={(e: React.FormEvent<HTMLInputElement>) => this.props.changeSelectedConnector(SCHUKO, e.currentTarget.checked)}
                                />
                                <label className='schuko-label type-checkbox-label' htmlFor="schuko">
                                    <span className="type-checkbox-logo">
                                        <Schuko_icon />
                                    </span>                        </label>
                                <div className="text-image">schuko </div>
                            </div>

                            <div className="phase-checkbox">
                                <input type="checkbox" id="phase" name="phase" value={PHASE} checked={this.props.selectedConnectorsArr.find(socket => socket === PHASE || socket === allTypesConnectors) ? true : false}
                                    onChange={(e: React.FormEvent<HTMLInputElement>) => this.props.changeSelectedConnector(PHASE, e.currentTarget.checked)}
                                />
                                <label className='phase-label type-checkbox-label' htmlFor="phase">
                                    <span className="type-checkbox-logo">
                                        <Phase_icon />
                                    </span>                        </label>
                                <div className="text-image">phase </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }

    getOperatorTypeImage = (): JSX.Element => {
        return (
            <div className="icon-and-tooltip" onClick={() => { this.props.filterPopup(); this.setState({ operatorsOpen: true }) }}>
                <div className="img-operator-type filter-popup-icon" data-tip data-for='img-operator-type'>
                    {/* <img src={operators_filter_icon} alt="operator type icon" /> */}
                    <span className='fill-color'>
                        <Operators_filter_icon />
                    </span>
                </div>
                <ReactTooltip id='img-operator-type'
                    type='light'
                    effect="solid"
                    border
                    place={(this.props.browserLanguage === BrowserLanguage.he || this.props.browserLanguage === BrowserLanguage.ar) ? 'left' : 'right'}
                >
                    <span className="icon-filter-tooltip">{this.props.stringsFromServer.site_FilterByOperators}</span>
                </ReactTooltip>
            </div>
        )
    }

    getOperatorsElement = (): JSX.Element => {
        const arrowClassName = this.state.operatorsOpen ? 'arrow-top' : 'arrow-bottom';
        const filter_popup_icon_class_name = this.state.operatorsOpen ? 'filter-popup-icon-open' : 'filter-popup-icon';
        const { operatorsArr, changeSelectedOprators } = this.props;
        const providerConsts = new ProviderConsts();

        return (
            <div>

                <div className={filter_popup_icon_class_name}
                    onClick={() => this.setState({
                        operatorsOpen: !this.state.operatorsOpen,
                        chargingPowerOpen: false,
                        socketsNumberOpen: false,
                        connectionTypeOpen: false,
                        paymentsOpen: false,
                        includeOpen: false,
                    })}
                >
                    {/* <img src={operators_filter_icon} alt="operator type icon" /> */}
                    <span className='fill-color'>
                        <Operators_filter_icon />
                    </span>
                    <span className="operators-type-title">
                        {this.props.stringsFromServer.site_FilterByOperators}
                    </span>
                    <span className={arrowClassName}></span>
                </div>

                <div className="operators-type-checkbox">
                    {this.state.operatorsOpen &&
                        <div className="">
                            <div className="all-checkbox">
                                <input type="checkbox" id="allOperators" name="allOperators" value={allTypesOperators} checked={operatorsArr.find(operator => operator === allTypesOperators) ? true : false}
                                    onChange={(e: React.FormEvent<HTMLInputElement>) => changeSelectedOprators(allTypesOperators, e.currentTarget.checked)}
                                />
                                <label className='all-label type-checkbox-label' htmlFor="allOperators">
                                    <span className="type-checkbox-logo">
                                        <All_img_svg />
                                    </span>                        </label>
                                <div className="text-image">{this.props.stringsFromServer.site_FilterAll} </div>
                            </div>
                            <div className="Afkon-checkbox">
                                <input type="checkbox" id="Afkon" name="Afkon" value={providerConsts.PROVIDER_AFKON} checked={operatorsArr.find(operator => operator === providerConsts.PROVIDER_AFKON || operator === allTypesOperators) ? true : false}
                                    onChange={(e: React.FormEvent<HTMLInputElement>) => changeSelectedOprators(providerConsts.PROVIDER_AFKON, e.currentTarget.checked)}
                                />
                                <label className='Afkon-label filter-popup-logo-open' htmlFor="Afkon">
                                     {/* <span className="filter-popup-logo-open"> */}
                                           <img  src={Afkon_Logo} className='Afkon-logo type-checkbox-logo' alt="Afkon logo" /> 
                                           {/* </span> */}
                                </label>
                                <div className="text-image">Afkon EV </div>
                            </div>
                            <div className="EvEgde-checkbox">
                                <input type="checkbox" id="EvEgde" name="EvEgde" value={providerConsts.PROVIDER_EVEGDE} checked={operatorsArr.find(operator => operator === providerConsts.PROVIDER_EVEGDE || operator === allTypesOperators) ? true : false}
                                    onChange={(e: React.FormEvent<HTMLInputElement>) => changeSelectedOprators(providerConsts.PROVIDER_EVEGDE, e.currentTarget.checked)}
                                />
                                <label className='EvEgde-label filter-popup-logo-open' htmlFor="EvEgde">
                                    {/* <span className="filter-popup-logo-open"> */}
                                   
                                    <img className='EvEgde-logo type-checkbox-logo' src={EvEgde_Logo} alt="EvEgde logo" />
                                   {/* </span> */}
                                </label>
                                <div className="text-image">EV-Egde </div>
                            </div>
                       
                            <div className="gnrgy-checkbox">
                                <input type="checkbox" id="gnrgy" name="gnrgy" value={providerConsts.PROVIDER_GNRGY} checked={operatorsArr.find(operator => operator === providerConsts.PROVIDER_GNRGY || operator === allTypesOperators) ? true : false}
                                    onChange={(e: React.FormEvent<HTMLInputElement>) => changeSelectedOprators(providerConsts.PROVIDER_GNRGY, e.currentTarget.checked)}
                                />
                                <label className='gnrgy-label filter-popup-logo-open' htmlFor="gnrgy">
                                  {/* <span className='filter-popup-logo-open'> */}
                                    <img className='gnrgy-logo type-checkbox-logo' src={Gnrgy_img} alt="gnrgy logo" />
                                    {/* </span> */}
                                </label>
                                <div className="text-image">Gnrgy </div>
                            </div>
                            <br></br>
                            <div className="sonol-checkbox">
                                <input type="checkbox" id="sonol" name="sonol" value={providerConsts.PROVIDER_SONOL} checked={operatorsArr.find(operator => operator === providerConsts.PROVIDER_SONOL || operator === allTypesOperators) ? true : false}
                                    onChange={(e: React.FormEvent<HTMLInputElement>) => changeSelectedOprators(providerConsts.PROVIDER_SONOL, e.currentTarget.checked)}
                                />
                                <label className='sonol-label filter-popup-logo-open' htmlFor="sonol">
                                  {/* <div className="filter-popup-logo-open"> */}
                                    <img className='sonol-logo type-checkbox-logo' src={Sonol_Logo} alt="sonol logo" />
                                    {/* </div> */}
                                </label>
                                <div className="text-image">Sonol EVI </div>
                            </div>

                            <div className="netzer-checkbox">
                                <input type="checkbox" id="netzer" name="netzer" value={providerConsts.PROVIDER_NETZER} checked={operatorsArr.find(operator => operator === providerConsts.PROVIDER_NETZER || operator === allTypesOperators) ? true : false}
                                    onChange={(e: React.FormEvent<HTMLInputElement>) => changeSelectedOprators(providerConsts.PROVIDER_NETZER, e.currentTarget.checked)}
                                />
                                <label className='netzer-label filter-popup-logo-open' htmlFor="netzer">
                                    {/* <div className="filter-popup-logo-open"> */}
                                    <img className='netzer-logo type-checkbox-logo' src={Netzer_Logo} alt="netzer logo" />
                                    {/* </div> */}
                                </label>
                                <div className="text-image">Netzer </div>
                            </div>


                            <div className="ev4u-checkbox">
                                <input type="checkbox" id="ev4u" name="ev4u" value={providerConsts.PROVIDER_EV4U} checked={operatorsArr.find(operator => operator === providerConsts.PROVIDER_EV4U || operator === allTypesOperators) ? true : false}
                                    onChange={(e: React.FormEvent<HTMLInputElement>) => changeSelectedOprators(providerConsts.PROVIDER_EV4U, e.currentTarget.checked)}
                                />
                                <label className='ev4u-label filter-popup-logo-open' htmlFor="ev4u">
                                    {/* <div className="filter-popup-logo-open"> */}
                                    <img className='ev4u-logo type-checkbox-logo' src={Ev4u_Logo} alt="EV4U logo" />
                                    {/* </div> */}
                                </label>
                                <div className="text-image">EV4U </div>
                            </div>


                            <div className="edgecontrol-checkbox">
                                <input type="checkbox" id="edgecontrol" name="edgecontrol" value={providerConsts.PROVIDER_EDGE_CONTROL} checked={operatorsArr.find(operator => operator === providerConsts.PROVIDER_EDGE_CONTROL || operator === allTypesOperators) ? true : false}
                                    onChange={(e: React.FormEvent<HTMLInputElement>) => changeSelectedOprators(providerConsts.PROVIDER_EDGE_CONTROL, e.currentTarget.checked)}
                                />
                                <label className='edgecontrol-label filter-popup-logo-open' htmlFor="edgecontrol">
                                    {/* <div className="filter-popup-logo-open"> */}
                                    <img className='edgecontrol-logo type-checkbox-logo' src={EdgeControl_Logo} alt="Edge control logo" />
                                    {/* </div> */}
                                </label>
                                <div className="text-image">Edge Control </div>
                            </div>

                            <br></br>

                            <div className="scalaev-checkbox">
                                <input type="checkbox" id="scalaev" name="scalaev" value={providerConsts.PROVIDER_SCALAEV} checked={operatorsArr.find(operator => operator === providerConsts.PROVIDER_SCALAEV || operator === allTypesOperators) ? true : false}
                                    onChange={(e: React.FormEvent<HTMLInputElement>) => changeSelectedOprators(providerConsts.PROVIDER_SCALAEV, e.currentTarget.checked)}
                                />
                                <label className='scalaev-label filter-popup-logo-open' htmlFor="scalaev">
                                    {/* <div className="filter-popup-logo-open"> */}
                                    <img className='scalaev-logo type-checkbox-logo' src={ScalaEv_Logo} alt="Scala Ev logo" />
                                    {/* </div> */}
                                </label>
                                <div className="text-image">Scala Ev</div>
                            </div>


                            <div className="vimore-checkbox">
                                <input type="checkbox" id="vimore" name="vimore" value={providerConsts.PROVIDER_VIMORE} checked={operatorsArr.find(operator => operator === providerConsts.PROVIDER_VIMORE || operator === allTypesOperators) ? true : false}
                                    onChange={(e: React.FormEvent<HTMLInputElement>) => changeSelectedOprators(providerConsts.PROVIDER_VIMORE, e.currentTarget.checked)}
                                />
                                <label className='vimore-label filter-popup-logo-open' htmlFor="vimore">
                                    {/* <div className="filter-popup-logo-open"> */}
                                    <img className='vimore-logo type-checkbox-logo' src={ViMore_Logo} alt="Vi More logo" />
                                    {/* </div> */}
                                </label>
                                <div className="text-image">Vi More</div>
                            </div>


                            <div className="nofar-checkbox">
                                <input type="checkbox" id="nofar" name="nofar" value={providerConsts.PROVIDER_NOFAR} checked={operatorsArr.find(operator => operator === providerConsts.PROVIDER_NOFAR || operator === allTypesOperators) ? true : false}
                                    onChange={(e: React.FormEvent<HTMLInputElement>) => changeSelectedOprators(providerConsts.PROVIDER_NOFAR, e.currentTarget.checked)}
                                />
                                <label className='nofar-label filter-popup-logo-open' htmlFor="nofar">
                                    {/* <div className="filter-popup-logo-open"> */}
                                    <img className='nofar-logo type-checkbox-logo' src={Nofar_Logo} alt="Nofar logo" />
                                    {/* </div> */}
                                </label>
                                <div className="text-image">Nofar</div>
                            </div>


                            <div className="greems-checkbox">
                                <input type="checkbox" id="greems" name="greems" value={providerConsts.PROVIDER_GREEMS} checked={operatorsArr.find(operator => operator === providerConsts.PROVIDER_GREEMS || operator === allTypesOperators) ? true : false}
                                    onChange={(e: React.FormEvent<HTMLInputElement>) => changeSelectedOprators(providerConsts.PROVIDER_GREEMS, e.currentTarget.checked)}
                                />
                                <label className='greems-label filter-popup-logo-open' htmlFor="greems">
                                    {/* <div className="filter-popup-logo-open"> */}
                                    <img className='greems-logo type-checkbox-logo' src={Greems_Logo} alt="Greems logo" />
                                    {/* </div> */}
                                </label>
                                <div className="text-image">Greems</div>
                            </div>


                            <br></br>

                            <div className="tdsd-checkbox">
                                <input type="checkbox" id="tdsd" name="tdsd" value={providerConsts.PROVIDER_TDSD} checked={operatorsArr.find(operator => operator === providerConsts.PROVIDER_TDSD || operator === allTypesOperators) ? true : false}
                                    onChange={(e: React.FormEvent<HTMLInputElement>) => changeSelectedOprators(providerConsts.PROVIDER_TDSD, e.currentTarget.checked)}
                                />
                                <label className='tdsd-label filter-popup-logo-open' htmlFor="tdsd">
                                    {/* <div className="filter-popup-logo-open"> */}
                                    <img className='tdsd-logo type-checkbox-logo' src={TDSD_Logo} alt="tdsd logo" />
                                    {/* </div> */}
                                </label>
                                <div className="text-image">MRGV-Tech</div>
                            </div>

                            <div className="sevenev-checkbox">
                                <input type="checkbox" id="sevenev" name="sevenev" value={providerConsts.PROVIDER_SEVENEV} checked={operatorsArr.find(operator => operator === providerConsts.PROVIDER_SEVENEV || operator === allTypesOperators) ? true : false}
                                    onChange={(e: React.FormEvent<HTMLInputElement>) => changeSelectedOprators(providerConsts.PROVIDER_SEVENEV, e.currentTarget.checked)}
                                />
                                <label className='sevenev-label filter-popup-logo-open' htmlFor="sevenev">
                                    {/* <div className="filter-popup-logo-open"> */}
                                    <img className='sevenev-logo type-checkbox-logo' src={SevenEv_Logo} alt="sevenev logo" />
                                    {/* </div> */}
                                </label>
                                <div className="text-image">Seven EV</div>
                            </div>


                            <div className="lishatech-checkbox">
                                <input type="checkbox" id="lishatech" name="lishatech" value={providerConsts.PROVIDER_LISHATECH} checked={operatorsArr.find(operator => operator === providerConsts.PROVIDER_LISHATECH || operator === allTypesOperators) ? true : false}
                                    onChange={(e: React.FormEvent<HTMLInputElement>) => changeSelectedOprators(providerConsts.PROVIDER_LISHATECH, e.currentTarget.checked)}
                                />
                                <label className='lishatech-label filter-popup-logo-open' htmlFor="lishatech">
                                    {/* <div className="filter-popup-logo-open"> */}
                                    <img className='lishatech-logo type-checkbox-logo' src={Lishatech_Logo} alt="Energy One" />
                                    {/* </div> */}
                                </label>
                                <div className="text-image">Energy One</div>
                            </div>

                            <div className="doralurban-checkbox">
                                <input type="checkbox" id="doralurban" name="doralurban" value={providerConsts.PROVIDER_DORALURBAN} checked={operatorsArr.find(operator => operator === providerConsts.PROVIDER_SEVENEV || operator === allTypesOperators) ? true : false}
                                    onChange={(e: React.FormEvent<HTMLInputElement>) => changeSelectedOprators(providerConsts.PROVIDER_DORALURBAN, e.currentTarget.checked)}
                                />
                                <label className='doralurban-label filter-popup-logo-open' htmlFor="doralurban">
                                    {/* <div className="filter-popup-logo-open"> */}
                                    <img className='doralurban-logo type-checkbox-logo' src={Doralurban_Logo} alt="doralurban logo" />
                                    {/* </div> */}
                                </label>
                                <div className="text-image">Doral Urban</div>
                            </div>

                            <br></br>

                            <div className="zenev-checkbox">
                                <input type="checkbox" id="zenev" name="zenev" value={providerConsts.PROVIDER_ZENEV} checked={operatorsArr.find(operator => operator === providerConsts.PROVIDER_ZENEV || operator === allTypesOperators) ? true : false}
                                    onChange={(e: React.FormEvent<HTMLInputElement>) => changeSelectedOprators(providerConsts.PROVIDER_ZENEV, e.currentTarget.checked)}
                                />
                                <label className='zenev-label filter-popup-logo-open' htmlFor="zenev">
                                    {/* <div className="filter-popup-logo-open"> */}
                                    <img className='zenev-logo type-checkbox-logo' src={zenev_Logo} alt="zenev logo" />
                                    {/* </div> */}
                                </label>
                                <div className="text-image">Zen Energy</div>
                            </div>


                            <div className="enova-checkbox">
                                <input type="checkbox" id="enova" name="enova" value={providerConsts.PROVIDER_ENOVA} checked={operatorsArr.find(operator => operator === providerConsts.PROVIDER_ENOVA || operator === allTypesOperators) ? true : false}
                                    onChange={(e: React.FormEvent<HTMLInputElement>) => changeSelectedOprators(providerConsts.PROVIDER_ENOVA, e.currentTarget.checked)}
                                />
                                <label className='enova-label filter-popup-logo-open' htmlFor="enova">
                                    {/* <div className="filter-popup-logo-open"> */}
                                    <img className='enova-logo type-checkbox-logo' src={enova_Logo} alt="enova logo" />
                                    {/* </div> */}
                                </label>
                                <div className="text-image">Enova</div>
                            </div>


                            <div className="interev-checkbox">
                                <input type="checkbox" id="interev" name="interev" value={providerConsts.PROVIDER_INTEREV} checked={operatorsArr.find(operator => operator === providerConsts.PROVIDER_INTEREV || operator === allTypesOperators) ? true : false}
                                    onChange={(e: React.FormEvent<HTMLInputElement>) => changeSelectedOprators(providerConsts.PROVIDER_INTEREV, e.currentTarget.checked)}
                                />
                                <label className='interev-label filter-popup-logo-open' htmlFor="interev">
                                    {/* <div className="filter-popup-logo-open"> */}
                                    <img className='interev-logo type-checkbox-logo' src={interev_Logo} alt="interev logo" />
                                    {/* </div> */}
                                </label>
                                <div className="text-image">Inter EV</div>
                            </div>


                            {/* <div className="EVCharge-checkbox">
                                <input type="checkbox" id="EVCharge" name="EVCharge" value={providerConsts.PROVIDER_CHARGE} checked={operatorsArr.find(operator => operator === providerConsts.PROVIDER_CHARGE || operator === allTypesOperators) ? true : false}
                                    onChange={(e: React.FormEvent<HTMLInputElement>) => changeSelectedOprators(providerConsts.PROVIDER_CHARGE, e.currentTarget.checked)}
                                />
                                <label className='EvCharge-label filter-popup-logo-open' htmlFor="EVCharge">                                                                     
                                    <img className='EVCharge-logo type-checkbox-logo' src={EVCharge_Logo} alt="EVCharge logo" />
                                </label>
                                <div className="text-image">EV Charge </div>
                            </div> */}
                        </div>
                    }
                </div>
            </div>
        )
    }

    getLayersImage = (): JSX.Element => {
        return (
            <div className="icon-and-tooltip">
                <div className={`filter-popup-icon layers ${this.props.browserLanguage}`} data-tip data-for='layers'
                onClick={() => this.props.layersPopup()}
            >
                <span className='fill-color'>
                <Layers_icon/>
                 {/* <img src={layers_icon} alt="layers icon" /> */}
                </span>
            </div> 
            <ReactTooltip id='layers'
                    type='light'
                    effect="solid"
                    border
                    place={(this.props.browserLanguage === BrowserLanguage.he || this.props.browserLanguage === BrowserLanguage.ar) ? 'left' : 'right'}
                >
                    <span className="icon-filter-tooltip">{this.props.stringsFromServer.site_LayesTitle}</span>
            </ReactTooltip>
        </div>
        )
    }

    // getLayersElement = (): JSX.Element => {
    //     // return (
         
    //     // )
    // }


    getPaymentsTypeImage = (): JSX.Element => {
        return (
            <div className="icon-and-tooltip" onClick={() => { this.props.filterPopup(); this.setState({ paymentsOpen: true }) }}>
                <div className="img-payment-type filter-popup-icon" data-tip data-for='img-payment-type'>
                    <span className='fill-color'>
                        <Payments_filter_icon />
                    </span>
                    {/* <img src={payments_filter_icon} alt="payment type icon" /> */}
                </div>
                <ReactTooltip id='img-payment-type'
                    type='light'
                    effect="solid"
                    border
                    place={(this.props.browserLanguage === BrowserLanguage.he || this.props.browserLanguage === BrowserLanguage.ar) ? 'left' : 'right'}
                >
                    <span className="icon-filter-tooltip">{this.props.stringsFromServer.site_FilterByPayment}</span>
                </ReactTooltip>
            </div>
        )
    }

    getPaymentsElement = (): JSX.Element => {
        //PriceType
        const priceType = new PriceType();
        const by_card = priceType.by_card;
        const by_cash = priceType.by_cash;
        const by_phone = priceType.by_phone;
        const free = priceType.free;
        const { paymentsArr, changeSelectedPayment, stringsFromServer } = this.props;
        const arrowClassName = this.state.paymentsOpen ? 'arrow-top' : 'arrow-bottom';
        const filter_popup_icon_class_name = this.state.paymentsOpen ? 'filter-popup-icon-open' : 'filter-popup-icon';
        return (
            <div>

                <div className={filter_popup_icon_class_name}
                    onClick={() => this.setState({
                        paymentsOpen: !this.state.paymentsOpen,
                        chargingPowerOpen: false,
                        socketsNumberOpen: false,
                        connectionTypeOpen: false,
                        operatorsOpen: false,
                        includeOpen: false,
                    })}
                >
                    {/* <img src={payments_filter_icon} alt="payment type icon" /> */}
                    <span className='fill-color'>
                        <Payments_filter_icon />
                    </span>
                    <span className="payments-type-title">
                        {stringsFromServer.site_FilterByPayment}
                    </span>
                    <span className={arrowClassName}></span>
                </div>



                <div className="include-type-checkbox">
                    {this.state.paymentsOpen &&

                        <div className="">

                            <div className="all-checkbox">
                                <input type="checkbox" id="allPayments" name="allPayments" value={allTypesPayment} checked={paymentsArr.find(payment => payment === allTypesPayment) ? true : false}
                                    onChange={(e: React.FormEvent<HTMLInputElement>) => changeSelectedPayment(allTypesPayment, e.currentTarget.checked)}
                                />
                                <label className='all-label type-checkbox-label' htmlFor="allPayments">
                                    <span className="type-checkbox-logo">
                                        <All_img_svg />
                                    </span>
                                </label>
                                <div className="text-image">{stringsFromServer.site_FilterAll} </div>
                            </div>

                            <div className="by-card-checkbox">
                                <input type="checkbox" id="by_card" name="by_card" value={by_card} checked={paymentsArr.find(payment => payment === by_card || payment === allTypesPayment) !== undefined ? true : false}
                                    onChange={(e: React.FormEvent<HTMLInputElement>) => changeSelectedPayment(by_card, e.currentTarget.checked)}
                                />
                                <label className='by-card-label type-checkbox-label' htmlFor="by_card">
                                    <span className="type-checkbox-logo">
                                        <By_card_icon />
                                    </span>
                                </label>
                                <div className="text-image">{stringsFromServer.site_FilterPayByCard} </div>
                            </div>

                            <div className="by-cash-checkbox">
                                <input type="checkbox" id="by_cash" name="by_cash" value={by_cash} checked={paymentsArr.find(payment => payment === by_cash || payment === allTypesPayment) ? true : false}
                                    onChange={(e: React.FormEvent<HTMLInputElement>) => changeSelectedPayment(by_cash, e.currentTarget.checked)}
                                />
                                <label className='by-cash-label type-checkbox-label' htmlFor="by_cash">
                                    <span className="type-checkbox-logo">
                                        <By_cash_icon />
                                    </span>
                                </label>
                                <div className="text-image">{stringsFromServer.site_FilterPayByCash} </div>
                            </div>

                            <div className="by-phone-checkbox">
                                <input type="checkbox" id="by_phone" name="by_phone" value={by_phone} checked={paymentsArr.find(payment => payment === by_phone || payment === allTypesPayment) ? true : false}
                                    onChange={(e: React.FormEvent<HTMLInputElement>) => changeSelectedPayment(by_phone, e.currentTarget.checked)}
                                />
                                <label className='by-phone-label type-checkbox-label' htmlFor="by_phone">
                                    <span className="type-checkbox-logo">
                                        <By_phone_icon />
                                    </span>
                                </label>
                                <div className="text-image">{stringsFromServer.site_FilterPayByPhone} </div>
                            </div>

                            <div className="free-checkbox">
                                <input type="checkbox" id="free" name="free" value={free} checked={paymentsArr.find(payment => payment === free || payment === allTypesPayment) ? true : false}
                                    onChange={(e: React.FormEvent<HTMLInputElement>) => changeSelectedPayment(free, e.currentTarget.checked)}
                                />
                                <label className='free-label type-checkbox-label' htmlFor="free">
                                    <span className="type-checkbox-logo">
                                        <Free_icon />
                                    </span>
                                </label>
                                <div className="text-image">{stringsFromServer.site_FilterPayFree} </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }

    getClearFilterElement = (): JSX.Element => {
        //PriceType
        const priceType = new PriceType();
        const by_card = priceType.by_card;
        const by_cash = priceType.by_cash;
        const by_phone = priceType.by_phone;
        const free = priceType.free;
        const { paymentsArr, changeSelectedPayment, stringsFromServer } = this.props;
        const arrowClassName = this.state.paymentsOpen ? 'arrow-top' : 'arrow-bottom';
        const filter_popup_icon_class_name = this.state.paymentsOpen ? 'filter-popup-icon-open' : 'filter-popup-icon';
        return (
            <div>

                <div className={filter_popup_icon_class_name}
                    onClick={() => this.setState({
                        paymentsOpen: !this.state.paymentsOpen,
                        chargingPowerOpen: false,
                        socketsNumberOpen: false,
                        connectionTypeOpen: false,
                        operatorsOpen: false,
                        includeOpen: false,
                    })}
                >
                    {/* <img src={payments_filter_icon} alt="payment type icon" /> */}
                    <span className='fill-color'>
                        <Payments_filter_icon />
                    </span>
                    <span className="payments-type-title">
                        {stringsFromServer.site_FilterByPayment}
                    </span>
                    <span className={arrowClassName}></span>
                </div>



                <div className="include-type-checkbox">
                    {this.state.paymentsOpen &&

                        <div className="">

                            <div className="all-checkbox">
                                <input type="checkbox" id="allPayments" name="allPayments" value={allTypesPayment} checked={paymentsArr.find(payment => payment === allTypesPayment) ? true : false}
                                    onChange={(e: React.FormEvent<HTMLInputElement>) => changeSelectedPayment(allTypesPayment, e.currentTarget.checked)}
                                />
                                <label className='all-label type-checkbox-label' htmlFor="allPayments">
                                    <span className="type-checkbox-logo">
                                        <All_img_svg />
                                    </span>
                                </label>
                                <div className="text-image">{stringsFromServer.site_FilterAll} </div>
                            </div>

                            <div className="by-card-checkbox">
                                <input type="checkbox" id="by_card" name="by_card" value={by_card} checked={paymentsArr.find(payment => payment === by_card || payment === allTypesPayment) !== undefined ? true : false}
                                    onChange={(e: React.FormEvent<HTMLInputElement>) => changeSelectedPayment(by_card, e.currentTarget.checked)}
                                />
                                <label className='by-card-label type-checkbox-label' htmlFor="by_card">
                                    <span className="type-checkbox-logo">
                                        <By_card_icon />
                                    </span>
                                </label>
                                <div className="text-image">{stringsFromServer.site_FilterPayByCard} </div>
                            </div>

                            <div className="by-cash-checkbox">
                                <input type="checkbox" id="by_cash" name="by_cash" value={by_cash} checked={paymentsArr.find(payment => payment === by_cash || payment === allTypesPayment) ? true : false}
                                    onChange={(e: React.FormEvent<HTMLInputElement>) => changeSelectedPayment(by_cash, e.currentTarget.checked)}
                                />
                                <label className='by-cash-label type-checkbox-label' htmlFor="by_cash">
                                    <span className="type-checkbox-logo">
                                        <By_cash_icon />
                                    </span>
                                </label>
                                <div className="text-image">{stringsFromServer.site_FilterPayByCash} </div>
                            </div>

                            <div className="by-phone-checkbox">
                                <input type="checkbox" id="by_phone" name="by_phone" value={by_phone} checked={paymentsArr.find(payment => payment === by_phone || payment === allTypesPayment) ? true : false}
                                    onChange={(e: React.FormEvent<HTMLInputElement>) => changeSelectedPayment(by_phone, e.currentTarget.checked)}
                                />
                                <label className='by-phone-label type-checkbox-label' htmlFor="by_phone">
                                    <span className="type-checkbox-logo">
                                        <By_phone_icon />
                                    </span>
                                </label>
                                <div className="text-image">{stringsFromServer.site_FilterPayByPhone} </div>
                            </div>

                            <div className="free-checkbox">
                                <input type="checkbox" id="free" name="free" value={free} checked={paymentsArr.find(payment => payment === free || payment === allTypesPayment) ? true : false}
                                    onChange={(e: React.FormEvent<HTMLInputElement>) => changeSelectedPayment(free, e.currentTarget.checked)}
                                />
                                <label className='free-label type-checkbox-label' htmlFor="free">
                                    <span className="type-checkbox-logo">
                                        <Free_icon />
                                    </span>
                                </label>
                                <div className="text-image">{stringsFromServer.site_FilterPayFree} </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }
    getIncludeTypeImage = (): JSX.Element => {
        return (
            <div className="icon-and-tooltip" onClick={() => { this.props.filterPopup(); this.setState({ includeOpen: true }) }}>
                <div className="img-include-type filter-popup-icon" data-tip data-for='img-include-type'>
                    <span className='fill-color'>
                        <Include_filter_icon />
                    </span>
                    {/* <img src={include_filter_icon} alt="include icon" /> */}
                </div>
                <ReactTooltip id='img-include-type'
                    type='light'
                    effect="solid"
                    border
                    place={(this.props.browserLanguage === BrowserLanguage.he || this.props.browserLanguage === BrowserLanguage.ar) ? 'left' : 'right'}
                >
                    <span className="icon-filter-tooltip">{this.props.stringsFromServer.site_FilterByInclude}</span>
                </ReactTooltip>
            </div>
        )
    }

    getIncludeElement = (): JSX.Element => {
        const { includeArr, changeSelectedInclude, stringsFromServer } = this.props;
        const arrowClassName = this.state.includeOpen ? 'arrow-top' : 'arrow-bottom';
        const filter_popup_icon_class_name = this.state.includeOpen ? 'filter-popup-icon-open' : 'filter-popup-icon';
        return (
            <div>
                <div className={filter_popup_icon_class_name}
                    onClick={() => this.setState({
                        includeOpen: !this.state.includeOpen,
                        chargingPowerOpen: false,
                        socketsNumberOpen: false,
                        connectionTypeOpen: false,
                        operatorsOpen: false,
                        paymentsOpen: false,
                    })}
                >
                    <span className='fill-color' >
                        <Include_filter_icon />
                    </span>
                    {/* <img src={include_filter_icon} alt="include icon" /> */}
                    <span className="include-type-title">
                        {stringsFromServer.site_FilterByInclude}
                    </span>
                    <span className={arrowClassName}></span>
                </div>


                <div className="include-type-checkbox">
                    {this.state.includeOpen &&

                        <div className="">

                            <div className="all-checkbox">
                                <input type="checkbox" id="allIncludes" name="allIncludes" value={allTypesIncludes} checked={includeArr.find(include => include === allTypesIncludes) ? true : false}
                                    onChange={(e: React.FormEvent<HTMLInputElement>) => changeSelectedInclude(allTypesIncludes, e.currentTarget.checked)}
                                />
                                <label className='all-label type-checkbox-label' htmlFor="allIncludes">
                                    <span className="type-checkbox-logo">
                                        <All_img_svg />
                                    </span>
                                </label>
                                <div className="text-image">{stringsFromServer.site_FilterAll} </div>
                            </div>

                            <div className="wifi-checkbox">
                                <input type="checkbox" id="wifi" name="wifi" value="wifi" checked={includeArr.find(include => (include === IncludesType.WIFI || include === allTypesIncludes)) ? true : false}
                                    onChange={(e: React.FormEvent<HTMLInputElement>) => changeSelectedInclude(IncludesType.WIFI, e.currentTarget.checked)}
                                />
                                <label className='wifi-label type-checkbox-label' htmlFor="wifi">
                                    <span className="type-checkbox-logo">
                                        <Wifi_icon />
                                    </span>
                                </label>
                                <div className="text-image">{stringsFromServer.site_FilterWifi} </div>
                            </div>

                            <div className="restoom-checkbox">
                                <input type="checkbox" id="restoom" name="restoom" value="restoom" checked={includeArr.find(include => (include === IncludesType.RESTOOM || include === allTypesIncludes)) ? true : false}
                                    onChange={(e: React.FormEvent<HTMLInputElement>) => changeSelectedInclude(IncludesType.RESTOOM, e.currentTarget.checked)}
                                />
                                <label className='restoom-label type-checkbox-label' htmlFor="restoom">
                                    <span className="type-checkbox-logo">
                                        <Restoom_icon />
                                    </span>
                                </label>
                                <div className="text-image">{stringsFromServer.site_FilterRestroom} </div>
                            </div>

                            <div className="loading-checkbox">
                                <input type="checkbox" id="loading" name="loading" value="loading" checked={includeArr.find(include => (include === IncludesType.LOADING || include === allTypesIncludes)) ? true : false}
                                    onChange={(e: React.FormEvent<HTMLInputElement>) => changeSelectedInclude(IncludesType.LOADING, e.currentTarget.checked)}
                                />
                                <label className='loading-label type-checkbox-label' htmlFor="loading">
                                    <span className="type-checkbox-logo">
                                        <Loading_icon />
                                    </span>
                                </label>
                                <div className="text-image">{stringsFromServer.site_FilterLoading} </div>
                            </div>

                            <div className="shopping-checkbox">
                                <input type="checkbox" id="shopping" name="shopping" value="shopping" checked={includeArr.find(include => (include === IncludesType.SHOPPING || include === allTypesIncludes)) ? true : false}
                                    onChange={(e: React.FormEvent<HTMLInputElement>) => changeSelectedInclude(IncludesType.SHOPPING, e.currentTarget.checked)}
                                />
                                <label className='shopping-label type-checkbox-label' htmlFor="shopping">
                                    <span className="type-checkbox-logo">
                                        <Shopping_icon />
                                    </span>
                                </label>
                                <div className="text-image">{stringsFromServer.site_FilterShopping} </div>
                            </div>


                            <div className="parking-checkbox">
                                <input type="checkbox" id="parking" name="parking" value="parking" checked={includeArr.find(include => (include === IncludesType.PARKING || include === allTypesIncludes)) ? true : false}
                                    onChange={(e: React.FormEvent<HTMLInputElement>) => changeSelectedInclude(IncludesType.PARKING, e.currentTarget.checked)}
                                />
                                <label className='parking-label type-checkbox-label' htmlFor="parking">
                                    <span className="type-checkbox-logo">
                                        <Parking_icon />
                                    </span>
                                </label>
                                <div className="text-image">{stringsFromServer.site_FilterParking} </div>
                            </div>
                            
                        </div>
                    }
                </div>
            </div>
        )
    }

   
}

export default Filter;

interface FilterState {
    chargingPowerOpen: boolean;
    socketsNumberOpen: boolean;
    connectionTypeOpen: boolean;
    operatorsOpen: boolean;
    paymentsOpen: boolean;
    includeOpen: boolean;
}

interface FilterProps {
    filterPopup: Function;
    changeSelectedConnector: Function;
    changeSelectedOprators: Function;
    changeSelectedPayment: Function;
    changeSelectedInclude: Function;
    handleChangePower: Function;
    handleSocketsNumber: Function;
    browserLanguage: BrowserLanguage;
    openFilterPopup: boolean;
    selectedConnectorsArr: string[];
    operatorsArr: number[];
    paymentsArr: string[];
    includeArr: string[];
    powerSelected: number;
    socektNumber: number;
    stringsFromServer: StringsData;
    
    layersPopup: Function;
}