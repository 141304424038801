import { SportsRugbySharp, Widgets } from '@material-ui/icons';
import { Component, ReactNode } from 'react';

import pin from '../assets/images/pan_to_center_icon.png'

const markerStyle = {
    position: 'absolute' as 'absolute',
    top: "100%",
    left: "50%",
    transform: "translate(-50%, -100%)",
    width: "30px"
  };

const myLocationStyle = {
        "height": "20px",
        width: "20px",
        //"background-color": "rgba(67,184,233, 0.9)",
        "background-color": "red",
        "border-radius": "50%",
        "display": "inline-block"
};
class Marker extends Component<MarkerProps, MarkerState> {


    render() {
        const { text, lat, lng } = this.props;
        return (
            <div>
                <img style={markerStyle} src={pin} alt="pin" />
                {/* <span style={myLocationStyle}></span> */}
            </div>
        )
    }

}


interface MarkerProps {
    lat: string;
    lng: string;   
    text: string;
}

interface MarkerState {
    tooltipType: 'tooltip' | 'popup',    
    portalView:boolean;
}

export default Marker