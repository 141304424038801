import React, { Component } from 'react';
// import logo from './logo.svg';
import Map from './screens/Map';
import './App.css';


class App extends Component {

    
  render() {

    return (
      <div className="app">
        <Map />
      </div>
    )
  }

}

export default App;
