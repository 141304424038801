import React, { Component } from 'react';
import layers_icon from '../../assets/images/layers_icon.png';
import './Header.scss';
import SearchIcon from '@material-ui/icons/Search';
import go_to_icon from '../../assets/images/go_to_icon.png';
import { AddressInfo, SearchOption, LanguageOption, Coordinates } from '../../entities/iconData';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { BrowserLanguage } from '../../entities/BrowserLanguage';
import location_icon from '../../assets/images/location_icon.png';
import israel_icon from '../../assets/images/israel.png';
import russia_icon from '../../assets/images/russia.png';
import united_arab_icon from '../../assets/images/united-arab.png';
import united_states_icon from '../../assets/images/united-states.png';
import language_icon from '../../assets/images/language.svg';
import Select, { components } from 'react-select'
import station_1_icon from '../../assets/images/station_1_icon.png';
import station_2_icon from '../../assets/images/station_2_icon.png';
import busy_station_icon from '../../assets/images/busy_station_icon.png';
import inactive_station_icon from '../../assets/images/inactive_station_icon.png';
import comingSoon_station_icon from '../../assets/images/comingSoon_station_icon.png';
import { LayersFilters } from '../../entities/LayersFilters';
import filter_icon from '../../assets/images/filter_icon.png';
import { StringsData } from '../../entities/StringsData';
import { slide as Menu } from 'react-burger-menu'
import Popup from '../Popup/Popup';
import contact_us_icon from '../../assets/images/contact_us_icon.png'
import gmap from '../../assets/images/google-maps-icon.svg.png'
import { PopupType } from '../../entities/PopupType';
import { Icon, Popper } from '@material-ui/core';
import { kMaxLength } from 'buffer';

class Header extends Component<HeaderProps, HeaderState> {

    constructor(props: HeaderProps) {
        super(props);
        this.state = {
            openMenuBar: false,
            openPopup: false,
            popupType: PopupType.EMPTY,
            portalView:false,
        }
    }
    componentDidMount = () => {
     
        
        const portal = window.location.search.includes('portal');
        this.setState({ portalView: portal }); 
 
      }

    render() {
        const { browserLanguage, stringsFromServer, closeAllPopupsFunc, setSelectedStationCoordinates } = this.props
        const direction = (browserLanguage === 'ar' || browserLanguage === 'he') ? 'rtl' : 'ltr'
        return (
            <header className={`header-page ${browserLanguage}`}>
                {!this.state.portalView &&
                <div className={`title-header ${browserLanguage} desktop-only`}>
                    <img className={`title-header-img ${browserLanguage}`} src={station_1_icon} alt="staion icon" />
                    <span className={`title-header-text ${browserLanguage}`}>{stringsFromServer.site_MainHeader}</span>
                </div>
    }
                {/* <div className={`layers ${browserLanguage}`}
                    onClick={() => this.props.layersPopup()}
                >
                    <img src={layers_icon} alt="layers icon" />
                </div> */}
                <div className={`language-search ${browserLanguage} desktop-only`}>
                    <div className={`search ${browserLanguage}`}>
                        <Autocomplete
                            id="combo-box-demo"
                            fullWidth={true}
                            forcePopupIcon={false}
                            PopperComponent={(props: any) => (<Popper {...props} style={{ width: '23vw', direction: direction }} />)}
                            style={{ direction: direction }}
                            options={this.searchStations()}
                            getOptionLabel={(option: SearchOption) => option.name + ' - ' + option.address }
                            onChange={(event: any, newValue: SearchOption | null) => this.props.setSelectedStationCoordinates(newValue)}
                            renderInput={(params) =>
                                <TextField  {...params} style={{ width: '23vw',direction: direction }}
                                    label={<div className={`label-search-text ${browserLanguage}`}> 
                                    <SearchIcon />  {stringsFromServer.site_SearchText} </div>}
                                    variant="outlined" size="small">
                                </TextField>}
                            renderOption={(option: SearchOption) => (<div>
                                <div className='select-station-div' >
                                    <span className="select-name-img">
                                        <img
                                            src={this.getCorrectStationIcon(option.status)}
                                            alt="station icon">
                                        </img>
                                        {option.name}{option.address?' - ' + option.address:' '}
                                        {/* <img src={go_to_icon} alt="go to icon" />  */} 
                                       </span>  
                                       <div className={`distance-from-user ${browserLanguage} `}> {this.getStringDistance(option.distanceFromUser)} </div>
                                    </div> 
                                    {/* <div className="Navgo-to" onClick={() => this.wazeNavigation(option)}>
                                   <img src={go_to_icon} alt="go to icon" /> 
                                        <span className={`Navlabel ${browserLanguage} `}> {stringsFromServer.site_StationInfoNavigate} </span>
                                    </div> */}
                                    </div>  
                                )}
                        />
                    </div>
                    {//!this.state.portalView && 
                    }
                </div>
                    <div className={`language-select desktop-only ${browserLanguage} `}>
                        <img className={'language-icon'} src={language_icon} onClick={() => this.languageSelect()}/>
                        {this.languageSelect()}
                    </div>
                
                <div className="mobile-mode header-mobile">
                    {this.getPopupTitleFilter()}
                    <div className={`title-header ${browserLanguage} mobile-mode`}>
                         <img className={`title-header-img ${browserLanguage}`} src={station_1_icon} alt="staion icon" />
                         <span className={`title-header-text ${browserLanguage}`}>{stringsFromServer.site_MainHeader}</span>
                    </div>
                    <div className={`side-bar ${browserLanguage}`}>
                        <div className={this.state.openMenuBar ? "humburger humburger-open" : "humburger"}
                            onClick={() => {
                                this.setState({ openMenuBar: !this.state.openMenuBar })
                                closeAllPopupsFunc()
                            }}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        {this.state.openMenuBar ?
                            <div className={`menu-side-bar ${browserLanguage}`}>
                                <div className={`search ${browserLanguage}`}>
                                    <Autocomplete
                                        id="combo-box-demo"
                                        forcePopupIcon={false}
                                        fullWidth={true}
                                        PopperComponent={(props: any) => (<Popper {...props} style={{ width: '300px', direction: direction }} />)}
                                        options={this.searchStations()}
                                        getOptionLabel={(option: SearchOption) => option.name + ' - ' + option.address}
                                        onChange={(event: any, newValue: SearchOption | null) => {
                                            this.setState({ openMenuBar: false })
                                            setSelectedStationCoordinates(newValue);

                                        }}
                                        renderInput={(params) =>
                                            <TextField  {...params}
                                                    label={<div className={`label-search-text ${browserLanguage}`}> 
                                                    <SearchIcon />  {stringsFromServer.site_SearchText} </div>}
                                                variant="outlined" size="small">
                                            </TextField>
                                        }
                                        renderOption={(option: SearchOption) => (<div>
                                                  <div className='select-station-div' >
                                                        <span className="select-name-img">
                                                            <img
                                                                src={this.getCorrectStationIcon(option.status)}
                                                                alt="station icon">
                                                            </img>
                                                            {option.name}{option.address?' - ' + option.address:' '}
                                                            {/* <img src={go_to_icon} alt="go to icon" />  */} 
                                                        </span>  
                                                        <div className={`distance-from-user ${browserLanguage} `}> {this.getStringDistance(option.distanceFromUser)} </div>
                                                        </div> 
                                                        {/* <div className="Navgo-to" onClick={() => this.wazeNavigation(option)}> */}
                                                        <div className="Navgo-to"><img alt="gmap" src={gmap} onClick={() => this.googleMapsNavigation(option)}/>                                                            
                                                    {/* <img src={go_to_icon} alt="go to icon" /> */}
                                                    <span className={`Navlabel ${browserLanguage} `}> {stringsFromServer.site_StationInfoNavigate} </span>
                                                </div> </div>
                                                                )}/>
                                </div>
                                <div className={`language-select ${browserLanguage}`}>{this.languageSelect()}</div>
                                <div className="center">
                                    <div className="terms "
                                        onClick={() => this.openPopup(PopupType.TERMS_OF_USE)}
                                    >
                                        <span className='footer-text'> {stringsFromServer.site_TermsOfUse}</span>
                                    </div>
                                    <div className="accessibillty"
                                        onClick={() => this.openPopup(PopupType.ACCESSIBILITY_STATMENT)}
                                    >
                                        <span className='footer-text'>{stringsFromServer.site_AccessbillityText}</span>
                                    </div>

                                    <div className={`contact-us ${browserLanguage}`}
                                        onClick={() => this.openPopup(PopupType.POPUP_CONTACT)}
                                    >
                                        {/* <img
                                            src={contact_us_icon}
                                            alt="contact us icon"
                                        ></img> */}
                                        <span className='footer-text'> {stringsFromServer.site_ContactText}</span>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className={`not-show-menu-bar ${browserLanguage}`}></div>
                        }
                    </div>

                    {this.state.openPopup &&
                        <Popup
                            stringsFromServer={stringsFromServer}
                            openPopup={this.state.openPopup}
                            closePopup={this.closePopup}
                            popupType={this.state.popupType}
                            browserLanguage={browserLanguage}

                        ></Popup>
                    }
                </div>
 
            </header>
        )
    }

    getStringDistance = (distance: number) => {
        const KM = (this.props.browserLanguage === 'he') ? 'ק"מ' : 'Km';
        if (!distance) return ('');
        if (distance < 1) {
            return (distance.toString().slice(0, 4) + ' ' + KM);
        }
        return (' ' + Math.floor(distance) + ' ' + KM);
    }

    languageSelect = () => {
        const widthImg = 30;
        const options: LanguageOption[] = [
            { value: BrowserLanguage.he, label: 'עברית'},
            { value: BrowserLanguage.en, label: 'English'},
            { value: BrowserLanguage.ar, label: 'عربي'},
            { value: BrowserLanguage.ru, label: 'русский'},
        ];
        sessionStorage.setItem('lang', this.props.browserLanguage);
        let indexOptionLanguage = options.findIndex(opt => opt.value === this.props.browserLanguage);
        indexOptionLanguage = indexOptionLanguage > 0 ? indexOptionLanguage : 0;
        const { Option } = components;
        const IconOption = (props: any) => (
            <Option {...props} >
                 {props.data.label}
                 {/* {props.data.labelText} */}
             </Option>
        );
        const style = {
            control: (base: any) => ({
                ...base,
                border: 0,
                // This line disable the blue border
                boxShadow: "none",
                cursor: 'pointer',                
            })
        };

        return (
            <Select
                defaultValue={options[indexOptionLanguage]}
                options={options}
                components={{ IndicatorSeparator: () => null, Option: IconOption }}
                onChange={(languageOption: any) => this.handleChangeLanguage(languageOption.value)}
                styles={style}
                isSearchable={false}
                
            />
        );
    }

    getCorrectStationIcon(status: number): string {
        switch (status) {
            case (LayersFilters.AVAILABLE):
                return (station_2_icon);
            case (LayersFilters.BUSY):
                return (busy_station_icon);
            case (LayersFilters.INACTIVE):
                return (inactive_station_icon);
            case (LayersFilters.COMING_SOON):
                return (comingSoon_station_icon);
            default:
                return (station_2_icon);

        }
    }

    searchStations = (): SearchOption[] => {
        const criteriaSearchArr = this.props.addressInfoData.map((addressInfo: AddressInfo) => {
            const searchOption = {
                name: addressInfo.name ? addressInfo.name : '' ,
                address:addressInfo.streetName,
                id: addressInfo.id,
                status: addressInfo.status,
                connectorTypesArr: addressInfo.moreInfoStationData ? addressInfo.moreInfoStationData.connectorTypesArr : [],
                distanceFromUser: addressInfo.distanceFromUser
            }
            return (searchOption)
        })
        return (criteriaSearchArr.sort((a, b) => a.distanceFromUser - b.distanceFromUser));
    }

   wazeNavigation = (searchOpt: SearchOption) => {
        //console.log('addressInfo', addressInfo);
     
         let addressInfo = this.props.addressInfoData.filter(adrr=>adrr.id===searchOpt.id);
 /*       const  userLocation = {
            lat: addressInfo[0].lat,
            lng: addressInfo[0].lng,
        };
 */
         const wazeBaseUrl = "https://www.waze.com/";
        const wazeLanguage = this.getWazeLanguage();
        const wazeTo = 'to=ll.' + addressInfo[0].lat + '%2C' + addressInfo[0].lng;
        const wazeFrom = !this.props.userLocation ? '' : "&from=ll." + this.props.userLocation.lat + "%2C" + this.props.userLocation.lng;
        const wazeURL = wazeBaseUrl + wazeLanguage + "live-map/directions?" + wazeTo + wazeFrom;
        window.open(wazeURL, "_blank")
    }
 
    getWazeLanguage(): string {
        switch (this.props.browserLanguage) {
            case ('ru'):
            case ('ar'):
            case ('he'): {
                return (this.props.browserLanguage + '/');
            }
            case ('en-US'):
            default: {
                return ('');
            }
        }
    }

    googleMapsNavigation = (searchOpt: SearchOption) => {
        console.log('2222222');
        let addressInfo = this.props.addressInfoData.filter(adrr=>adrr.id===searchOpt.id);

         const googleMapsUrl = "https://www.google.com/maps/?q=" + addressInfo[0].lat + "," + addressInfo[0].lng;

         window.open(googleMapsUrl, "_blank")
        // <a href={"https://www.google.com/maps/?q=" + this.props.userLocation.lat + "," +
        // this.props.userLocation.lng} target="_blank"><img alt="gmap" src={gmap}/></a>
    }

    handleChangeLanguage = (newLanguage: string) => {
        console.log('newLanguage', newLanguage);
        this.props.changeLanguage(newLanguage)
    }

    getPopupTitleFilter = () => {
        return (
            <div className={`title-filter-mobile mobile-mode ${this.props.browserLanguage}`}
                onClick={() => {
                    this.props.filterPopup();
                    // this.setState({
                    //     chargingPowerOpen: false,
                    //     socketsNumberOpen: false,
                    //     connectionTypeOpen: false,
                    //     operatorsOpen: false,
                    //     paymentsOpen: false,
                    //     includeOpen: false,
                    // })
                }}                >
                <span className="title-img">
                    <img src={filter_icon} alt="filter icon" />
                </span>
                {/* <span className="title-text">Filter</span> */}
            </div>
        );
    }


    openPopup = (popupType: PopupType) => {
        this.setState({ openPopup: true, popupType });
        this.props.closeAllPopupsFunc();
    }

    closePopup = () => {
        this.setState({ openPopup: false })
    }

}

interface HeaderProps {
    browserLanguage: BrowserLanguage;
    addressInfoData: AddressInfo[];
    setSelectedStationCoordinates: Function;
    filterPopup: Function;
    layersPopup: Function;
    changeLanguage: Function;
    stringsFromServer: StringsData;
    closeAllPopupsFunc: Function;
    userLocation?: Coordinates;

}

interface HeaderState {
    openMenuBar: boolean;
    openPopup: boolean;
    popupType: PopupType;
    portalView:boolean;

}


export default Header