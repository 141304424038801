import React, { Component } from 'react';
import { Coordinates } from '../../entities/iconData';
import './ClusterMap.scss'

class ClusterMap extends Component<ClusterMapProps>{

    render() {
        const coordinates: Coordinates = {
            lat: this.props.lat,
            lng: this.props.lng
        }
        return (
            <div className="cluster-map"
                onClick={() => this.props.clickCluster(coordinates)}
            >
                <span className="circle">
                    <label className="label-circle">{this.props.counter}</label>
                </span>
            </div>
        )
    }

}
export default ClusterMap;

interface ClusterMapProps {
    mapZoom: number;
    counter: number;
    lat: number;
    lng: number;
    clickCluster: Function;
}